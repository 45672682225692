<section class="section-padding advertisement-banner-1 center-bg-effect">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-lg-12">
				<div class="sigma-adv-content-1 center-block-div">
					<h6 class="sub-head">Our Causes</h6>
					<h3 class="text-white heading">Lets Change The World <span class="text-white">With Humanity</span>
					</h3>
					<!-- <div class="ad-count justify-content-center">
						<div class="countdown-box sigma_countdown-timer-white" data-countdown="2022/12/06"> </div>
					</div> -->
					<!-- <a routerLink="/story-archive" class="btn btn-text btn-text-white mt-20">Become A Volunteer</a> -->
				</div>
			</div>
		</div>
	</div>
</section>
