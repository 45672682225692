import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.css']
})
export class BlogDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  banner = "assets/img/blog/details.jpg";
  commentimg1 = "assets/img/blog/user1.jpg";
  commentimg2 = "assets/img/blog/user2.jpg";
  commentimg3 = "assets/img/blog/user3.jpg";
  commentimg4 = "assets/img/blog/user4.jpg";

}
