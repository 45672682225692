<!-- Sucscriber -->
	<section class="section-padding block_newsletter bg-theme-primary">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<div class="section-header-left text-center">
						<h3 class="text-light-black header-title">Grab Our Newsletter</h3>
						<p>To receive latest offers and discounts from the shop. </p>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<div class="subscribe-wrapper">
						<form id="subscribe-form" method="post" action="#">
							<div class="subscribe-content">
								<input type="text" name="subscribe-input" id="subscribe-input" value="" placeholder="Enter Your Email Address" class="form-control input-text required-entry validate-email">
								<button class="button" type="button"><span>Subscribe</span></button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- END Subscriber -->