<app-topbar></app-topbar>
<app-navbar></app-navbar>
<!-- breadcrumb -->
<app-breadcrumbs></app-breadcrumbs>
<!-- breadcrumb -->
<!--product Start-->
<section class="section-padding our-product bg-light-white">
	<div class="container-fluid custom-container">
		<div class="row">
			<!-- <div class="col-xl-3 col-lg-4">
				<app-shop-sidebar></app-shop-sidebar>
			</div> -->
			<div class="col-12">
				<div class="full-width">
					<div class="row">
						<div class="col-md-6 col-sm-12" *ngFor="let shopgrid of shopgridboxes">
              <app-shop-item [item]="shopgrid"></app-shop-item>
						</div>
					</div>
				</div>
				<!-- Pagination -->
				<!-- <app-pagination></app-pagination> -->
			</div>
		</div>
	</div>
</section>
<!--Product-end-->
<app-newsletter2></app-newsletter2>
<app-footer></app-footer>
