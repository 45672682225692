import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-story-detail',
  templateUrl: './story-detail.component.html',
  styleUrls: ['./story-detail.component.css']
})
export class StoryDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  storybanner= 'assets/img/story-big/mainstory.jpg';
  commentimg1 = "assets/img/blog/user1.jpg";
  commentimg2 = "assets/img/blog/user2.jpg";
  commentimg3 = "assets/img/blog/user3.jpg";
  commentimg4 = "assets/img/blog/user4.jpg";

  // Story Gallery
  storygallerypost = [
    {img:"assets/img/story-big/storyg8.jpg"},
    {img:"assets/img/story-big/storyg7.jpg"},
    {img:"assets/img/story-big/storyg6.jpg"},
    {img:"assets/img/story-big/storyg5.jpg"},
    {img:"assets/img/story-big/storyg4.jpg"},
    {img:"assets/img/story-big/storyg3.jpg"},
    {img:"assets/img/story-big/storyg2.jpg"},
    {img:"assets/img/story-big/storyg1.jpg"},
  ];

}
