import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.css']
})
export class WishlistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  // Wishlist data
  wishlistposts = [
    {img:"assets/img/shop/product5.jpg",productname:"Diamond Exclusive Ornament",price:"295.00",instock:"In Stock",btntext:"Add To Cart"},
    {img:"assets/img/shop/product2.jpg",productname:"Perfect Diamond Jewellery",price:"275.00",instock:"In Stock",btntext:"Add To Cart"},
    {img:"assets/img/shop/product3.jpg",productname:"Handmade Golden Necklace",price:"295.00",outstock:"Stock Out",btntext:"Add To Cart"},
    {img:"assets/img/shop/product4.jpg",productname:"Diamond Exclusive Ornament",price:"110.00",instock:"In Stock",btntext:"Add To Cart"}
  ];

}
