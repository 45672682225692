import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { SwiperModule } from 'swiper/angular';
import { BreadcrumbModule } from 'angular-crumbs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { TopbarComponent } from './components/layouts/topbar/topbar.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { ShopSidebarComponent } from './components/layouts/shop-sidebar/shop-sidebar.component';
import { DonationSidebarComponent } from './components/layouts/donation-sidebar/donation-sidebar.component';
import { BlogSidebarComponent } from './components/layouts/blog-sidebar/blog-sidebar.component';
import { StorySidebarComponent } from './components/layouts/story-sidebar/story-sidebar.component';
import { Newsletter1Component } from './components/layouts/newsletter1/newsletter1.component';
import { Newsletter2Component } from './components/layouts/newsletter2/newsletter2.component';
import { Homepage1Component } from './components/pages/homepage1/homepage1.component';
import { Homepage2Component } from './components/pages/homepage2/homepage2.component';
import { Homepage3Component } from './components/pages/homepage3/homepage3.component';
import { Homepage4Component } from './components/pages/homepage4/homepage4.component';
import { Homepage5Component } from './components/pages/homepage5/homepage5.component';
import { AboutusComponent } from './components/pages/aboutus/aboutus.component';
import { ShopLeftComponent } from './components/pages/shop-left/shop-left.component';
import { ShopRightComponent } from './components/pages/shop-right/shop-right.component';
import { ShopDetailComponent } from './components/pages/shop-detail/shop-detail.component';
import { DonationArchiveComponent } from './components/pages/donation-archive/donation-archive.component';
import { DonationDetailComponent } from './components/pages/donation-detail/donation-detail.component';
import { WishlistComponent } from './components/pages/wishlist/wishlist.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { OrderComponent } from './components/pages/order/order.component';
import { Error404Component } from './components/pages/error404/error404.component';
import { LoginComponent } from './components/pages/login/login.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { BlogRightComponent } from './components/pages/blog-right/blog-right.component';
import { BlogLeftComponent } from './components/pages/blog-left/blog-left.component';
import { BlogDetailComponent } from './components/pages/blog-detail/blog-detail.component';
import { StoryArchiveComponent } from './components/pages/story-archive/story-archive.component';
import { StoryDetailComponent } from './components/pages/story-detail/story-detail.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { AdvertisementComponent } from './components/layouts/advertisement/advertisement.component';
import { TestimonialsComponent } from './components/layouts/testimonials/testimonials.component';
import { BlogpostComponent } from './components/layouts/blogpost/blogpost.component';
import { LatestproductsComponent } from './components/layouts/latestproducts/latestproducts.component';
import { BreadcrumbsComponent } from './components/layouts/breadcrumbs/breadcrumbs.component';
import { PaginationComponent } from './components/layouts/pagination/pagination.component';
import { ShoprelatedComponent } from './components/layouts/shoprelated/shoprelated.component';
import { SiteStatsComponent } from './components/layouts/site-stats/site-stats.component';
import { VideoComponent } from './components/layouts/video/video.component';
import { ShopItemComponent } from './components/layouts/shop-item/shop-item.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    TopbarComponent,
    FooterComponent,
    ShopSidebarComponent,
    DonationSidebarComponent,
    BlogSidebarComponent,
    StorySidebarComponent,
    Newsletter1Component,
    Newsletter2Component,
    Homepage1Component,
    Homepage2Component,
    Homepage3Component,
    Homepage4Component,
    Homepage5Component,
    AboutusComponent,
    ShopLeftComponent,
    ShopRightComponent,
    ShopDetailComponent,
    DonationArchiveComponent,
    DonationDetailComponent,
    WishlistComponent,
    CheckoutComponent,
    OrderComponent,
    Error404Component,
    LoginComponent,
    RegisterComponent,
    BlogRightComponent,
    BlogLeftComponent,
    BlogDetailComponent,
    StoryArchiveComponent,
    StoryDetailComponent,
    ContactComponent,
    AdvertisementComponent,
    TestimonialsComponent,
    BlogpostComponent,
    LatestproductsComponent,
    BreadcrumbsComponent,
    PaginationComponent,
    ShoprelatedComponent,
    SiteStatsComponent,
    VideoComponent,
    ShopItemComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SwiperModule,
    BreadcrumbModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    HttpClientModule
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
