<section class="feedback-area-two section-padding bg-custom-primary">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-12">
				<div class="section-header-left text-center">
					<h3 class="text-white header-title">What The Community Say</h3>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<swiper [config]="testimonialconfig" class="feedback-slider p-relative swiper-container">
					<div class="swiper-wrapper">
						<div class="swiper-slide" *ngFor="let testimonial of testimonials">
							<div class="feedback-item-two">
								<img src="{{ testimonial.img }}" alt="Feedback">
								<p class="text-white fs-16">{{ testimonial.text }}</p>
								<h5 class="text-white fw-600 no-margin">{{ testimonial.name }}</h5>
								<span class="text-white fw-400">{{ testimonial.designation }}</span>
							</div>
						</div>
					</div>
					<!-- Add Arrows -->
					<div class="swiper-button-next"></div>
					<div class="swiper-button-prev"></div>
				</swiper>
			</div>
		</div>
	</div>
</section>