<div class="side-bar ">
  <div class="main-box padding-20 side-blog mb-xl-20">
    <h5 class="text-light-black">Peoples Story</h5>
    <article class="side-post {{ story.class }}" *ngFor="let story of stories">
			<div class="thumb-img">
				<a routerLink="/story-detail">
					<img src="{{ story.img }}" alt="thumb-img">
				</a>
			</div>
			<div class="content-wrap">
				<div class="entry-meta-content">
					<h6 class="entry-title">
						<a routerLink="/story-detail" class="text-light-black">{{ story.title }}</a>
					</h6>
					<div class="entry-tye"> <span class="text-light-white fw-400">{{ story.name }}</span>
					</div>
				</div>
			</div>
    </article>
  </div>
  <div class="main-box padding-20 blog-tags mb-xl-20">
    <h5 class="text-light-black">Tags</h5>
    <a routerLink="/story-detail" *ngFor="let tag of tags">#{{tag.title}}</a>
  </div>
  <div class="main-box padding-20 trending-blog-cat mb-xl-20">
    <h5 class="text-light-black">Archive</h5>
    <ul>
      <li class="pb-xl-20 u-line mb-xl-20" *ngFor="let archive of archives">
				<a routerLink="/story-detail" class="text-light-black fw-600">
					{{archive.name}}
					<span class="text-light-white fw-400">({{archive.count}})</span>
				</a>
			</li>
    </ul>
  </div>
</div>