<div class="side-bar mb-md-40">
	<div class="main-box padding-20 side-shop mb-xl-20">
		<h5 class="text-light-black">Top Products</h5>
		<article class="side-post {{ topproduct.class }}" *ngFor="let topproduct of topproducts">
			<div class="thumb-img">
				<a routerLink="/shop-detail">
					<img src="{{ topproduct.img }}" alt="thumb-img">
				</a>
			</div>
			<div class="content-wrap">
				<div class="entry-meta-content">
					<h6 class="entry-title">
						<a routerLink="/shop-detail" class="text-light-black">{{ topproduct.title }}</a>
					</h6>
					<div class="entry-tye"> <span class="text-color-primary fs-18 fw-600">${{ topproduct.price }}</span>
					</div>
				</div>
			</div>
		</article>
	</div>
	<div class="main-box padding-20 trending-blog-cat mb-xl-20">
		<h5 class="text-light-black">Price</h5>
		<div class="delivery-slider">
			<input type="text" class="delivery-range-slider" value="" />
		</div>
	</div>
	<!-- <div class="main-box padding-20 trending-blog-cat mb-xl-20">
		<h5 class="text-light-black">Brands</h5>
		<div class="filter">
			<form>
				<label class="custom-checkbox" *ngFor="let brand of brands">
					<input type="checkbox" name="#"> <span class="checkmark"></span> {{ brand.title }} <span
						class="text-light-white">({{ brand.count }})</span>
				</label>
			</form>
		</div>
	</div> -->
	<div class="main-box padding-20 trending-blog-cat mb-xl-20">
		<h5 class="text-light-black">Size</h5>
		<div class="filter">
			<form>
				<label class="custom-radio" *ngFor="let weight of weights">
					<input type="radio" name="#"> <span class="radio"></span> {{ weight.title }}
				</label>
			</form>
		</div>
	</div>
	<div class="main-box padding-20 blog-tags mb-xl-20">
		<h5 class="text-light-black">Tags</h5>
		<a routerLink="/shop-detail" *ngFor="let tag of tags">#{{tag.title}}</a>
	</div>
	<!-- <div class="main-box padding-20 trending-blog-cat mb-xl-20">
		<h5 class="text-light-black">Categories</h5>
		<ul>
			<li class="pb-xl-20 u-line mb-xl-20" *ngFor="let category of categories">
				<a routerLink="/shop-detail" class="text-light-black fw-600">
					{{category.name}}
					<span class="text-light-white fw-400">({{category.count}})</span>
				</a>
			</li>
		</ul>
	</div> -->
</div>
