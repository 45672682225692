<div class="page-404 loader" id="page-404">
	
<section>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="404-img">
              <h1 class="text-404">4<span class="text-white">0</span>4</h1>
            </div>
            <div class="caption white-block">
              <h5 class="text-custom-white rubik full-width">Opps! Page Not Found. <a href="javascript:void(0)" class="text-custom-white">Try Again</a></h5>
              <a routerLink="/" class="btn btn-text mt-20">Go Back Home </a>
            </div>
          </div>
        </div>
      </div>
  </section>

	
</div>
