<div class="side-bar">
	<div class="main-box padding-20 side-blog mb-xl-20">
		<h5 class="text-light-black">Popular Donation</h5>
		<article class="side-post {{ donation.class }}" *ngFor="let donation of popdonations">
			<div class="thumb-img">
				<a routerLink="/donation-detail">
					<img src="{{ donation.img }}" alt="thumb-img">
				</a>
			</div>
			<div class="content-wrap">
				<div class="entry-meta-content">
					<h6 class="entry-title">
						<a routerLink="/donation-detail" class="text-light-black">{{ donation.title }}</a>
					</h6>
					<div class="entry-tye"> <span class="text-light-white fw-400">{{ donation.date }}</span>
					</div>
				</div>
			</div>
		</article>
	</div>
	<div class="main-box padding-20 blog-tags mb-xl-20">
		<h5 class="text-light-black">Tags</h5>
		<a routerLink="/donation-detail" *ngFor="let tag of tags">#{{tag.title}}</a>
	</div>
	<div class="main-box padding-20 trending-blog-cat mb-xl-20">
		<h5 class="text-light-black">Categories</h5>
		<ul>
			<li class="pb-xl-20 u-line mb-xl-20" *ngFor="let category of categories">
				<a routerLink="/donation-detail" class="text-light-black fw-600">
					{{category.name}}
					<span class="text-light-white fw-400">({{category.count}})</span>
				</a>
			</li>
		</ul>
	</div>
</div>