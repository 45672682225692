import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-story-sidebar',
  templateUrl: './story-sidebar.component.html',
  styleUrls: ['./story-sidebar.component.css']
})
export class StorySidebarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  // Popular post
  stories = [
    { class: "pb-xl-20 mb-xl-20 u-line", img: "assets/img/blog/sblog4.jpg", title: "Brooklyn For A Better Place", name: "Adem Rich" },
    { class: "pb-xl-20 mb-xl-20 u-line", img: "assets/img/blog/sblog3.jpg", title: "15 Things To know Before Voting", name: "Jim Lux" },
    { class: "pb-xl-20 mb-xl-20 u-line", img: "assets/img/blog/sblog2.jpg", title: "Vote For The Best Party In Broolyn", name: "Michle Rolls" },
    { img: "assets/img/blog/sblog1.jpg", title: "Best Political Party blog in Brooklyn", name: "Kim Lui" }
  ];
  // Tags
  tags = [
    { title: "Story" },
    { title: "NY" },
    { title: "Education" },
    { title: "Archive" },
    { title: "Rich" },
    { title: "Goal" },
    { title: "Raised" },
    { title: "Homeless" }
  ];
  // Archives
  archives = [
    { name: "2011", count: "110" },
    { name: "2012", count: "98" },
    { name: "2013", count: "52" },
    { name: "2014", count: "26" },
    { name: "2015", count: "55" },
    { name: "2016", count: "48" },
    { name: "2017", count: "50" }
  ];

}
