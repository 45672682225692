import { Component, OnInit } from '@angular/core';
import { ShopItem } from 'src/app/schemas/shop';

@Component({
  selector: 'app-shop-left',
  templateUrl: './shop-left.component.html',
  styleUrls: ['./shop-left.component.css'],
})
export class ShopLeftComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  shopgridboxes: ShopItem[] = [
    {
      images: ['assets/img/shop/obidients-cap.jpeg', 'assets/img/shop/obidients-tshirt.jpeg'],
      tagclass: 'new',
      tagname: 'Accessories',
      carttext: 'Add To Cart',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Campaign Shirt',
      price: '9.00',
      discountprice: '8.00',
    },
    {
      images: ['assets/img/shop/obidients-tshirt.jpeg', 'assets/img/shop/obidients-cap.jpeg'],
      tagclass: 'new',
      tagname: 'Accessories',
      carttext: 'Add To Cart',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Campaign Pens',
      price: '3.00',
      discountprice: '2.50',
    },
    {
      images: ['assets/img/shop/obidients-cap.jpeg', 'assets/img/shop/obidients-tshirt.jpeg'],
      tagclass: 'new',
      tagname: 'Accessories',
      carttext: 'Add To Cart',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Obidient Sunglasses',
      price: '9.00',
      discountprice: '8.00',
    },
    {
      images: ['assets/img/shop/obidients-tshirt.jpeg', 'assets/img/shop/obidients-cap.jpeg'],
      tagclass: 'new',
      tagname: 'Accessories',
      carttext: 'Add To Cart',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Campaign Notebook',
      price: '3.00',
      discountprice: '2.50',
    },
    {
      images: ['assets/img/shop/obidients-cap.jpeg', 'assets/img/shop/obidients-tshirt.jpeg'],
      tagclass: 'new',
      tagname: 'Accessories',
      carttext: 'Add To Cart',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Campaign Overalls',
      price: '23.00',
      discountprice: '20.00',
    },
    {
      images: ['assets/img/shop/obidients-tshirt.jpeg', 'assets/img/shop/obidients-cap.jpeg'],
      tagclass: 'new',
      tagname: 'Accessories',
      carttext: 'Add To Cart',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Campaign Cap',
      price: '15.00',
      discountprice: '15.00',
    },
    {
      images: ['assets/img/shop/obidients-cap.jpeg', 'assets/img/shop/obidients-tshirt.jpeg'],
      tagclass: 'new',
      tagname: 'Accessories',
      carttext: 'Add To Cart',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Obidient Hoodie',
      price: '15.00',
      discountprice: '14.00',
    },
    {
      images: ['assets/img/shop/obidients-tshirt.jpeg', 'assets/img/shop/obidients-cap.jpeg'],
      tagclass: 'new',
      tagname: 'Accessories',
      carttext: 'Add To Cart',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Campaign Shirt 2',
      price: '9.00',
      discountprice: '8.00',
    },
    {
      images: ['assets/img/shop/obidients-cap.jpeg', 'assets/img/shop/obidients-tshirt.jpeg'],
      tagclass: 'new',
      tagname: 'Accessories',
      carttext: 'Add To Cart',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Campaign Box Set',
      price: '35.00',
      discountprice: '29.00',
    },
    {
      images: ['assets/img/shop/obidients-tshirt.jpeg', 'assets/img/shop/obidients-cap.jpeg'],
      tagclass: 'new',
      tagname: 'Accessories',
      carttext: 'Add To Cart',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Campaign Umbrella',
      price: '9.00',
      discountprice: '5.00',
    },
    // {img:"assets/img/shop/obidients-tshirt.jpeg",tagclass:"new",tagname:"Accessories",carttext:"Add To Cart",soldprice:"45,000",goalprice:"70,000",title:"Campaign Shirt",price:"90.00",discountprice:"15.00"},
    // {img:"assets/img/shop/obidients-cap.jpeg",tagclass:"new",tagname:"Accessories",carttext:"Add To Cart",soldprice:"45,000",goalprice:"70,000",title:"Campaign Shirt",price:"90.00",discountprice:"15.00"},
    // {img:"assets/img/shop/obidients-tshirt.jpeg",tagclass:"new",tagname:"Accessories",carttext:"Add To Cart",soldprice:"45,000",goalprice:"70,000",title:"Campaign Shirt",price:"90.00",discountprice:"15.00"},
    // {img:"assets/img/shop/obidients-cap.jpeg",tagclass:"new",tagname:"Accessories",carttext:"Add To Cart",soldprice:"45,000",goalprice:"70,000",title:"Campaign Shirt",price:"90.00",discountprice:"15.00"},
    // {img:"assets/img/shop/obidients-tshirt.jpeg",tagclass:"new",tagname:"Accessories",carttext:"Add To Cart",soldprice:"45,000",goalprice:"70,000",title:"Campaign Shirt",price:"90.00",discountprice:"15.00"},
    // {img:"assets/img/shop/obidients-cap.jpeg",tagclass:"new",tagname:"Accessories",carttext:"Add To Cart",soldprice:"45,000",goalprice:"70,000",title:"Campaign Shirt",price:"90.00",discountprice:"15.00"}
  ];
}
