<app-topbar></app-topbar>
<app-navbar></app-navbar>
<!-- breadcrumb -->
<app-breadcrumbs></app-breadcrumbs>
<!-- breadcrumb -->
<!-- Blog -->
<section class="our-articles bg-light-theme section-padding ">
	<div class="container-fluid custom-container">
		<div class="row">

			<div class="col-xl-9 col-lg-8 mb-md-40">
				<div class="full-width">
					<div class="row">
						<div class="col-xl-4 col-lg-6 col-md-6" *ngFor="let blogbox of blogboxes">
							<article class="blog-item blog-item-box">
								<div class="blog-item-img">
									<img class="blog-img" src="{{blogbox.img}}" alt="img">
									<ul class="blog-item-badge">
										<li *ngIf="blogbox.tag1"><a routerLink="/blog-detail">{{blogbox.tag1}}</a> </li>
										<li *ngIf="blogbox.tag2"><a routerLink="/blog-detail">{{blogbox.tag2}}</a></li>
									</ul>
								</div>
								<div class="blog-item-content">
									<h5 class="blog-item-title"><a routerLink="/blog-detail">{{blogbox.title}}</a></h5>
									<p>{{blogbox.text}}</p>
									<div class="blog-item-details">
										<span class="blog-item-date"><i class="fas fa-calendar-week"></i> {{blogbox.postdate}}</span>
										<span><i class="fas fa-comment-dots"></i> {{blogbox.commentcount}}</span></div>
								</div>
							</article>
						</div>
					</div>
				</div>
				<!-- Pagination -->
				<app-pagination></app-pagination>
			</div>
			<aside class="col-xl-3 col-lg-4">
				<app-blog-sidebar></app-blog-sidebar>
			</aside>
		</div>
	</div>
</section>
<app-newsletter1></app-newsletter1>
<app-footer></app-footer>