<app-topbar></app-topbar>
<app-navbar></app-navbar>
<!-- breadcrumb -->
<app-breadcrumbs></app-breadcrumbs>
<!-- breadcrumb -->
<!-- about us -->
<section class="aboutus section-padding">
  <div class="container-fluid custom-container">
    <div class="row align-items-center justify-content-between">
      <div class="col-12">
        <div class="history-title mb-lg-40">
          <h2 class="text-light-black">
            The Obidients Online Store
            <span class="text-color-primary">initiative</span>
          </h2>
          <p class="text-light-white mb-xl-40 fw-700">
            Leading efforts for Diaspora engagements
          </p>
          <!-- <h4 class="text-light-black">Barr Abure</h4> -->
          <div class="row justify-content-between">
            <div class="col-12">
              <div class="about-box text-light-white mb-xl-20">
                <p>
                  Barr, Julius Abure, National Chairman of Labour Party (LP) on
                  Sept 1st 2022 inaugurated an 11-man committee to drive the
                  party’s Disapora movement . Abure, while inaugurating the
                  committee in Abuja said that the move was to strengthen
                  collaboration among people and galvanise support for the party
                  ahead of 2023 general election. He added that the committee
                  would also recommend to the national chairman, the creation of
                  diaspora chapters and monitor their activities. The chairman
                  said the committee would organise fund-raising activities
                  among others.
                </p>
                <p>
                  He said, “I have the honour to inaugurate the committee we
                  have put in place on diaspora. “The parties has enjoyed
                  overwhelming supports from Nigerians across the world and even
                  non Nigerians alike. “This has resulted in a situation where
                  there is clamour for the party to establish chapters across
                  the world. ” There has also been the clamour of people who
                  wants to contribute to the growth and development of the
                  party, arising from this course, are the necessity to have
                  these activities coordinated.”
                </p>
                <p>
                  Abure said that the party therefore, decided to put up the
                  committee made of 11 distinguished Nigerians to drive the
                  process.
                </p>

                <p>
                  Mr Chibuike Amadichi, Chairman, Diaspora Committee ,thanked
                  the party for the privileged to lead the committee. Amadichi
                  said that the 2023 is a special electioneering year because it
                  is an election that all Nigerians both home and in Diaspora
                  are fully involved in to see the birth of a new nation .
                </p>

                <p>
                  “Few months ago, they said Labour Party had no structures, but
                  today, Labour Party is not only building structures nationwide
                  in Nigeria, but also advancing its frontiers beyond the shores
                  of Nigeria.
                </p>
                <p>
                  “This diaspora committee will work tirelessly to reposition
                  our great party and build a new partnership with our people in
                  diaspora especially in the major countries of the world like
                  USA, UK, Canada, Germany, South Africa just to mention but a
                  few
                </p>

                <p>
                  ” I can say without mincing words that our people in diaspora
                  are willing to identify with Labour Party and also ready to
                  support the party and its presidential candidates “I want to
                  assure our Nigerians in diaspora that come next year 2023,
                  Labour Party will form a new government that will midwife a
                  new Nigeria with a man of proven integrity and competent as
                  the president,”he said
                </p>

                <p>
                  <small class="text-muted">
                    <a href="https://labourparty.com.ng/labour-party-inaugurates-11-man-diaspora-committee-to-raise-funds/" target="_blank">source</a>
                  </small>
                </p>
              </div>
            </div>
          </div>
          <!-- <div class="col-xl-3">
				<div class="row">
					<div class="col-xl-12 col-md-4 col-sm-6" *ngFor="let quotepost of quoteposts">
						<div class="about-quote-box">
							<h6 class="text-custom-white">{{ quotepost.text }}</h6>
							<p class="text-custom-white">By {{ quotepost.author }}</p>
							<i class="fas fa-quote-right"></i>
						</div>
					</div>
				</div>
			</div> -->
        </div>
      </div>
    </div>
  </div>
</section>
<!-- about us -->
<!-- How It Woks -->
<!-- <section class="section-padding how-it-works bg-theme-primary">
	<div class="container">
		<div class="section-header-style-2">
			<h6 class="text-light-green sub-title">Our Process</h6>
			<h3 class="text-light-black header-title">How Does It Work</h3>
		</div>
		<div class="row">
			<div class="col-lg-3 col-sm-6" *ngFor="let processpost of processposts">
				<div class="how-it-works-box {{ processpost.arrowclass }}">
					<div class="how-it-works-box-inner">
						<span class="icon-box">
							<img src="{{ processpost.icon }}" alt="icon">
							<span class="number-box">{{ processpost.number }}</span>
						</span>
						<h6>{{ processpost.title }}</h6>
						<p>{{ processpost.description }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</section> -->
<!-- How It Woks -->
<!--Testimonail-->
<!-- <app-testimonials></app-testimonials> -->
<!--Testimonail End-->
<!-- Blog section -->
<!-- <app-blogpost></app-blogpost> -->
<!-- END/BLOG Section -->
<app-newsletter2></app-newsletter2>
<app-footer></app-footer>
