<app-topbar></app-topbar>
<app-navbar></app-navbar>
<!-- breadcrumb -->
<!-- <app-breadcrumbs></app-breadcrumbs> -->
<!-- breadcrumb -->
<!-- Blog -->
<main id="main-content" class="p-relative">
  <section class="p-relative single-banner">
    <img src="{{bannerImg}}" class="img-fluid full-width" alt="Banner">
    <div class="transform-center">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8 align-self-center">
            <div class="right-side-content text-center">
              <!-- <h5 class="text-white">Around the world.</h5> -->
              <h1 class="text-white fw-600 fs-60">Donate</h1>
              <!-- <p class="text-white fw-400">Gray eel-catfish longnose whiptail catfish smalleye squaretail
                queen danio unicorn fish
                shortnose greeneye fusilier fish silver carp nibbler sharksucker tench lookdown catfishf</p> -->
              <button class="btn-solid with-line btn-big mt-20" (click)="open(content)"><span>Donate now<i
                    class="fas fa-caret-right"></i></span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="overlay overlay-bg-dark-2"></div>
  </section>

  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Enter Amount</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <form>
        <div class="mb-3">
          <label for="dateOfBirth">Amount</label>
          <div class="input-group">
            <input id="dateOfBirth" class="form-control" placeholder="$..." name="dp">
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
    </div>
  </ng-template>
    </main>
<app-newsletter2></app-newsletter2>
<app-footer></app-footer>
