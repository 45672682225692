<div>
  <div class="product-box mb-md-20">
    <div class="product-img" (mouseenter) ="toggleSwipe(1)"  (mouseleave) ="toggleSwipe(0)">
        <swiper [config]="config" (autoplayStop)="resetSlide()" #usefulSwiper>
          <ng-template swiperSlide *ngFor="let img of item.images">
           <div class="shopitem-image" style="background-image: url({{img}});"></div>
          </ng-template>
          <!-- <div class="swiper-pagination"></div>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div> -->
        </swiper>
        <!-- <a routerLink="/shop-detail">
      </a> -->
      <!-- <div class="product-badge">
        <div class="product-label {{ item.tagclass }}"> <span>{{ item.tagname }}</span>
        </div>
      </div> -->
      <div class="button-group z-index-5">
        <a routerLink="/wishlist" placement="left" title=""
          ngbTooltip="Add to wishlist" tabindex="-1">
          <i class="pe-7s-like"></i>
        </a>
        <a routerLink="/" placement="left" title="" ngbTooltip="Quick View">
          <span>
            <i class="pe-7s-search"></i>
          </span>
        </a>
      </div>
      <div class="shopitem-words z-index-4">
       <span class="word-1">
        "We must become a producing nation"
        - PO
       </span>

       <span class="word-2">
        This will go a long way to support the campaign of Peter Obi and Ahmad Datti
       </span>
      </div>
      <div class="cart-hover z-index-5">
        <a routerLink="/shop-detail" class="btn-cart  fw-600" tabindex="-1">{{ item.carttext }}</a>
      </div>
    </div>
    <!-- <div class="product-caption text-center">
      <div class="product-status">
        <ul class="product-raised">
          <li><strong>Sold:</strong> {{ item.soldprice }}</li>
          <li><strong>Goal:</strong><span class="text-highlight"> {{ item.goalprice }}</span></li>
        </ul>
        <div class="progress">
          <div class="progress-bar progress-bar-color" style="width: 75%;"></div>
        </div>
      </div>
      <h6 class="product-title fw-500 mt-10"><a routerLink="/shop-detail"
          class="text-color-secondary">{{ item.title }}</a></h6>
      <div class="product-money mt-10"> <span
          class="text-color-primary fw-600 fs-16">${{ item.price }}</span>
        <span class="text-price">${{ item.discountprice }}</span>
      </div>
    </div> -->
  </div>
</div>
