<div id="banner-adv" class="banner-adv">
	<a routerLink="/order"> <span class="text">Get FREE DELIVERY on your first order.</span>
	</a> <span class="close-banner"></span>
</div>
<app-topbar></app-topbar>
<app-navbar></app-navbar>
<!-- breadcrumb -->
<app-breadcrumbs></app-breadcrumbs>
<!-- breadcrumb -->
<!-- tracking map -->
<section class="checkout-page section-padding bg-light-white">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-md-12">
				<div class="tracking-sec">
					<div class="tracking-details padding-20 p-relative">
						<h5 class="text-light-black fw-600">Sports Shoes</h5>
						<span class="text-light-white">Estimated Delivery time</span>
						<h6 class="text-light-black fw-500 no-margin">9:00pm-9:10pm</h6>
						<div id="add-listing-tab" class="step-app">
							<ul class="step-steps">
								<li class="done">
									<a href="javascript:void(0)"> <span class="number"></span>
										<span class="step-name">Order sent<br>8:38pm</span>
									</a>
								</li>
								<li class="active">
									<a href="javascript:void(0)"> <span class="number"></span>
										<span class="step-name">In the works</span>
									</a>
								</li>
								<li>
									<a href="javascript:void(0)"> <span class="number"></span>
										<span class="step-name">Out of delivery</span>
									</a>
								</li>
								<li>
									<a href="javascript:void(0)"> <span class="number"></span>
										<span class="step-name">Delivered</span>
									</a>
								</li>
							</ul>
							<button type="button" class="fullpageview text-light-black fw-600"
								data-modal="modal-12">Full Page View</button>
						</div>
					</div>
					<div class="tracking-map">
						<iframe id="pickupmap"
							src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d1572264.578242286!2d-76.35629003051189!3d39.67605529079796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!3m2!1d40.7127753!2d-74.0059728!4m5!1s0x89b7c6de5af6e45b%3A0xc2524522d4885d2a!2sWashington%20D.%20C.%2C%20DC%2C%20USA!3m2!1d38.9071923!2d-77.0368707!5e0!3m2!1sen!2sin!4v1593620930391!5m2!1sen!2sin"
							frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
							tabindex="0"></iframe>
					</div>
				</div>
				<!-- recipt -->
				<div class="recipt-sec padding-20">
					<div class="recipt-name title u-line full-width mb-xl-20">
						<div class="recipt-name-box">
							<h5 class="text-light-black fw-600 mb-2">Sport Shoes</h5>
							<p class="text-light-white ">Estimated Delivery time</p>
						</div>
						<div class="countdown-box timer_wrap" data-countdown="2022/12/06">
						</div>
					</div>
					<div class="u-line mb-xl-20">
						<div class="row">
							<div class="col-lg-4">
								<div class="recipt-name full-width padding-tb-10 pt-0">
									<h5 class="text-light-black fw-600">Delivery (ASAP) to:</h5>
									<span class="text-light-white ">Jhon Moxley</span>
									<span class="text-light-white ">Home</span>
									<span class="text-light-white ">314 79th St</span>
									<span class="text-light-white ">Rite Aid, Brooklyn, NY, 11209</span>
									<p class="text-light-white ">(347) 123456789</p>
								</div>
							</div>
							<div class="col-lg-4">
								<div class="recipt-name full-width padding-tb-10 pt-0">
									<h5 class="text-light-black fw-600">Delivery instructions</h5>
									<p class="text-light-white ">Lorem ipsum dolor sit amet, consectetur adipiscing
										elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
										consectetur adipiscing elit.</p>
								</div>
							</div>
							<div class="col-lg-4">
								<div class="ad-banner padding-tb-10 h-100">
									<img src="assets/img/shop/check1.jpg" class="img-fluid full-width" alt="banner-adv">
								</div>
							</div>
						</div>
					</div>
					<div class="mb-xl-20">
						<div class="row">
							<div class="col-lg-12">
								<h5 class="text-light-black fw-600 title">Your Order <span><a routerLink="/order"
											class="fs-12">Print recipt</a></span></h5>
								<p class="title text-light-white">Jan 15, 2022 8:38pm <span
										class="text-light-black">Order #123456789012345</span>
								</p>
							</div>
							<div class="col-lg-12">
								<div class="checkout-product" *ngFor="let orderlist of orderlistpost">
									<div class="img-name-value">
										<div class="product-img">
											<a routerLink="/shop-detail">
												<img src="{{orderlist.img}}" alt="#">
											</a>
										</div>
										<div class="product-value"> <span
												class="text-light-white">{{orderlist.items}}</span>
										</div>
										<div class="product-name"> <span><a routerLink="/shop-detail"
													class="text-light-white">Belt</a></span>
										</div>
									</div>
									<div class="amount">
										<span class="text-light-white">ID: #{{orderlist.id}}</span>
									</div>
									<div class="Category">
										<span class="text-light-white">{{orderlist.title}}</span>
									</div>
									<div class="price"> <span class="text-light-white">${{orderlist.price}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-lg-7">
							<div class="payment-method mb-md-40">
								<h5 class="text-light-black fw-600">Payment Method</h5>
								<div class="method-type"> <i class="far fa-credit-card text-dark-white"></i>
									<span class="text-light-white">Credit Card</span>
								</div>
							</div>
						</div>
						<div class="col-lg-5">
							<div class="price-table u-line">
								<div class="item"> <span class="text-light-white">Item subtotal:</span>
									<span class="text-light-white">$30.5</span>
								</div>
								<div class="item"> <span class="text-light-white">Delivery fee:</span>
									<span class="text-light-white">$30.5</span>
								</div>
								<div class="item"> <span class="text-light-white">Tax and fees:</span>
									<span class="text-light-white">$30.5</span>
								</div>
								<div class="item"> <span class="text-light-white">Driver tip:</span>
									<span class="text-light-white">$30.5</span>
								</div>
							</div>
							<div class="total-price padding-tb-10">
								<h5 class="title text-light-black fw-500">Total: <span>$33.36</span></h5>
							</div>
						</div>
						<div class="col-12 d-flex"> <a routerLink="/checkout"
								class="btn-first white-btn fw-600 help-btn">Need Help?</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- tracking map -->
<app-newsletter2></app-newsletter2>
<app-footer></app-footer>
