<div class="container-fluid custom-container">
    <div class="row">
        <div class="col-12">
            <div class="section-header-left title">
                <h3 class="text-light-black header-title">Our Latest Products</h3>
                <ul ngbNav #nav="ngbNav" class="nav nav-pills shop-tabs">
                    <li class="nav-item" ngbNavItem>
                        <a ngbNavLink class="nav-link" href="#">Our Best Sellers</a>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6"
                                    *ngFor="let ourseller of ourbestsellers">
                                    <div class="product-box mb-md-20">
                                        <div class="product-img">
                                            <a routerLink="/shop-detail">
                                                <img src="{{ourseller.img}}" class="img-fluid full-width"
                                                    alt="product-img">
                                            </a>
                                            <div class="product-badge">
                                                <div class="product-label {{ourseller.labelcls}}">
                                                    <span>{{ourseller.label}}</span>
                                                </div>
                                            </div>
                                            <div class="button-group">
                                                <a routerLink="/wishlist" placement="left" title=""
                                                    ngbTooltip="Add to wishlist" tabindex="-1">
                                                    <i class="pe-7s-like"></i>
                                                </a>
                                                <a routerLink="/" placement="left" title="" ngbTooltip="Quick View">
                                                    <span>
                                                        <i class="pe-7s-search"></i>
                                                    </span>
                                                </a>
                                            </div>
                                            <div class="cart-hover">
                                                <a routerLink="/shop-detail" class="btn-cart  fw-600" tabindex="-1">Add
                                                    To
                                                    Cart</a>
                                            </div>
                                        </div>
                                        <div class="product-caption text-center">
                                            <div class="product-status">
                                                <ul class="product-raised">
                                                    <li><strong>Sold:</strong> {{ourseller.soldprice}}</li>
                                                    <li><strong>Goal:</strong><span class="text-highlight">
                                                            {{ourseller.goalprice}}</span></li>
                                                </ul>
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-color" style="width: 75%;">
                                                    </div>
                                                </div>
                                            </div>
                                            <h6 class="product-title fw-500 mt-10"><a routerLink="/shop-detail"
                                                    class="text-color-secondary">{{ourseller.title}} </a></h6>
                                            <div class="product-money mt-10"> <span
                                                    class="text-color-primary fw-600 fs-16">${{ourseller.price}}</span>
                                                <span class="text-price">${{ourseller.discountprice}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li class="nav-item" ngbNavItem>
                        <a ngbNavLink class="nav-link" href="#">Most Rated</a>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6"
                                    *ngFor="let mostrated of mostratedposts">
                                    <div class="product-box mb-md-20">
                                        <div class="product-img">
                                            <a routerLink="/shop-detail">
                                                <img src="{{mostrated.img}}" class="img-fluid full-width"
                                                    alt="product-img">
                                            </a>
                                            <div class="product-badge">
                                                <div class="product-label {{mostrated.labelcls}}">
                                                    <span>{{mostrated.label}}</span>
                                                </div>
                                            </div>
                                            <div class="button-group">
                                                <a routerLink="/wishlist" placement="left" title=""
                                                    ngbTooltip="Add to wishlist" tabindex="-1">
                                                    <i class="pe-7s-like"></i>
                                                </a>
                                                <a routerLink="/" placement="left" title="" ngbTooltip="Quick View">
                                                    <span>
                                                        <i class="pe-7s-search"></i>
                                                    </span>
                                                </a>
                                            </div>
                                            <div class="cart-hover">
                                                <a routerLink="/shop-detail" class="btn-cart  fw-600" tabindex="-1">Add
                                                    To
                                                    Cart</a>
                                            </div>
                                        </div>
                                        <div class="product-caption text-center">
                                            <div class="product-status">
                                                <ul class="product-raised">
                                                    <li><strong>Sold:</strong> {{mostrated.soldprice}}</li>
                                                    <li><strong>Goal:</strong><span class="text-highlight">
                                                            {{mostrated.goalprice}}</span></li>
                                                </ul>
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-color" style="width: 75%;">
                                                    </div>
                                                </div>
                                            </div>
                                            <h6 class="product-title fw-500 mt-10"><a routerLink="/shop-detail"
                                                    class="text-color-secondary">{{mostrated.title}} </a></h6>
                                            <div class="product-money mt-10"> <span
                                                    class="text-color-primary fw-600 fs-16">${{mostrated.price}}</span>
                                                <span class="text-price">${{mostrated.discountprice}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li class="nav-item" ngbNavItem>
                        <a ngbNavLink class="nav-link" href="#">Most Selling</a>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6"
                                    *ngFor="let mostselling of mostsellingpost">
                                    <div class="product-box mb-md-20">
                                        <div class="product-img">
                                            <a routerLink="/shop-detail">
                                                <img src="{{mostselling.img}}" class="img-fluid full-width"
                                                    alt="product-img">
                                            </a>
                                            <div class="product-badge">
                                                <div class="product-label {{mostselling.labelcls}}">
                                                    <span>{{mostselling.label}}</span>
                                                </div>
                                            </div>
                                            <div class="button-group">
                                                <a routerLink="/wishlist" placement="left" title=""
                                                    ngbTooltip="Add to wishlist" tabindex="-1">
                                                    <i class="pe-7s-like"></i>
                                                </a>
                                                <a routerLink="/" placement="left" title="" ngbTooltip="Quick View">
                                                    <span>
                                                        <i class="pe-7s-search"></i>
                                                    </span>
                                                </a>
                                            </div>
                                            <div class="cart-hover">
                                                <a routerLink="/shop-detail" class="btn-cart  fw-600" tabindex="-1">Add
                                                    To
                                                    Cart</a>
                                            </div>
                                        </div>
                                        <div class="product-caption text-center">
                                            <div class="product-status">
                                                <ul class="product-raised">
                                                    <li><strong>Sold:</strong> {{mostselling.soldprice}}</li>
                                                    <li><strong>Goal:</strong><span class="text-highlight">
                                                            {{mostselling.goalprice}}</span></li>
                                                </ul>
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-color" style="width: 75%;">
                                                    </div>
                                                </div>
                                            </div>
                                            <h6 class="product-title fw-500 mt-10"><a routerLink="/shop-detail"
                                                    class="text-color-secondary">{{mostselling.title}} </a></h6>
                                            <div class="product-money mt-10"> <span
                                                    class="text-color-primary fw-600 fs-16">${{mostselling.price}}</span>
                                                <span class="text-price">${{mostselling.discountprice}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li class="nav-item"><a class="nav-link" routerLink="/shop-detail">See All</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="tab-content">
        <div [ngbNavOutlet]="nav"></div>
    </div>
</div>