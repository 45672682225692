<section class="section-padding blog-section bg-theme-primary">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-12">
				<div class="section-header-left title">
					<h3 class="text-light-black header-title">Our Latest News</h3>
					<span class="fs-16"><a routerLink="/blog-right">See All</a></span>
				</div>
			</div>
			<div class="col-xl-3 col-lg-3 col-md-6" *ngFor="let blogpost of blogposts">
				<article class="blog-item blog-item-box">
					<div class="blog-item-img">
						<img class="blog-img" src="{{ blogpost.img }}" alt="img">
						<ul class="blog-item-badge">
							<li *ngIf="blogpost.tag1"><a routerLink="/blog-detail">{{ blogpost.tag1 }}</a> </li>
							<li *ngIf="blogpost.tag2"><a routerLink="/blog-detail">{{ blogpost.tag2 }}</a></li>
						</ul>
					</div>
					<div class="blog-item-content">
						<h5 class="blog-item-title"><a routerLink="/blog-detail">{{ blogpost.title }}</a></h5>
						<p>{{ blogpost.description }}</p>
						<div class="blog-item-details">
							<span class="blog-item-date"><i class="fas fa-calendar-week"></i> {{ blogpost.postdate }}</span>
							<span><i class="fas fa-comment-dots"></i> {{ blogpost.postcomment }}</span>
						</div>
					</div>
				</article>
			</div>
		</div>
	</div>
</section>