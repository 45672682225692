<div class="inner-wrapper">
		<div class="container-fluid no-padding">
			<div class="row no-gutters overflow-auto">
				<div class="col-md-6">
					<div class="main-banner"> 
						<img src="{{banner}}" class="img-fluid full-width main-img" alt="banner">
					</div>
				</div>
				<div class="col-md-6">
					<div class="section-2 user-page main-padding">
						<div class="login-sec">
							<div class="login-box">
								<form>
									<h4 class="text-light-black fw-600">Sign in with your Ecom account</h4>
									<div class="row">
										<div class="col-12">
											<p class="text-light-black">Have a corporate username? <a routerLink="/login">Click here</a>
											</p>
											<div class="form-group">
												<label class="text-light-white fs-14">Email</label>
												<input type="email" name="#" class="form-control form-control-submit" placeholder="Email I'd" required>
											</div>
											<div class="form-group">
												<label class="text-light-white fs-14">Password</label>
												<input type="password" id="password-field" name="#" class="form-control form-control-submit" value="password" placeholder="Password" required>
												<div data-name="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"></div>
											</div>
											<div class="form-group checkbox-reset">
												<label class="custom-checkbox mb-0">
													<input type="checkbox" name="#"> <span class="checkmark"></span> Keep me signed in</label> <a routerLink="/login">Reset password</a>
											</div>
											<div class="form-group">
												<button type="submit" class="btn-second-2 btn-submit full-width">Sign in</button>
											</div>
											<div class="form-group text-center"> <span>or</span>
											</div>
											<div class="form-group">
												<button type="submit" class="btn-second-2 btn-facebook full-width">
													<img src="assets/img/facebook-logo.svg" alt="btn logo">Continue with Facebook</button>
											</div>
											<div class="form-group">
												<button type="submit" class="btn-second-2 btn-google full-width">
													<img src="assets/img/google-logo.png" alt="btn logo">Continue with Google</button>
											</div>
											<div class="form-group text-center mb-0"> <a routerLink="/register">Create your account</a>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>