<app-topbar></app-topbar>
<app-navbar></app-navbar>
<!-- breadcrumb -->
<app-breadcrumbs></app-breadcrumbs>
<!-- breadcrumb -->
<!-- Blog -->
<section class="our-articles bg-light-theme section-padding ">
  <div class="container-fluid custom-container">
    <div class="row">
      <div class="col-xl-9 col-lg-8">
        <div class="main-box padding-20 full-width sa-causes-single sa-causes-single-2 mb-md-40">
          <div class="post-wrapper mb-xl-20">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="profile-img full-height">
                  <img src="{{storybanner}}" alt="img" class="img-fluid full-width full-height">
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="profile-content">
                  <h4>Dr. Michle Colen</h4>
                  <p><strong>From:</strong><br> Brooklyn, NY</p>
                  <p><strong>About:</strong><br> Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel
                    facilisis.</p>
                  <p><strong>Donation:</strong></p><br>
                  <div class="cause-progress profile-donate">
                    <div class="progress-bar" role="progressbar" aria-valuenow="17" aria-valuemin="0"
                      aria-valuemax="100" style="width: 17%;">
                      <span>17%</span>
                    </div>
                  </div>
                  <div class="causes-amount">
                    <div class="left">
                      <p class="text-dark">Raised</p>
                      <span>$4585.00</span>
                    </div>
                    <div class="right">
                      <p class="text-dark">Goal</p>
                      <span>$4585.00</span>
                    </div>
                  </div>
                  <div class="bottom-group">
                    <a routerLink="/story-detail" class="btn-solid with-line"><span>Donate Now <i
                          class="fas fa-caret-right"></i></span></a>
                    <div class="social-media-box">
                      <ul class="custom">
                        <li><a routerLink="/story-detail" class="fb"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a routerLink="/story-detail" class="tw"><i class="fab fa-twitter"></i></a></li>
                        <li><a routerLink="/story-detail" class="gg"><i class="fab fa-google-plus-g"></i></a></li>
                        <li><a routerLink="/story-detail" class="ln"><i class="fab fa-linkedin-in"></i></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="blog-meta mb-xl-20">
                <h5 class="blog-title text-light-black">Best Democratic Party in Brooklyn.</h5>


                <p class="text-light-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                  took a galley of type and
                  scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
                  into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
                  release of Letraset
                  sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus
                  PageMaker including versions of Lorem Ipsum.</p>
              </div>
              <div class="blog-meta mb-xl-20">
                <h6 class="blog-title text-light-black">Why do we choose it?</h6>
                <p class="text-light-white">It is a long established fact that a reader will be distracted by the
                  readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a
                  more-or-less normal
                  distribution of letters, as opposed to using 'Content here, content here', making it look like
                  readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their
                  default model text, and a search
                  for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved
                  over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
              </div>
              <blockquote>
                <p>"Chinese is the popular Producrt, Lorem Ipsum is not simply random text. It has roots in a piece of
                  classical chinese."</p>
              </blockquote>
              <div class="blog-meta mb-xl-20">
                <h6 class="blog-title text-light-black">Where can I get that product?</h6>
                <p class="text-light-white">There are many variations of passages of Lorem Ipsum available, but the
                  majority have suffered alteration in some form, by injected humour, or randomised words which don't
                  look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure
                  there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the
                  Internet tend to repeat predefined chunks as necessary, making this the first true generator on the
                  Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence
                  structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore
                  always free from repetition, injected humour, or non-characteristic words etc.</p>
              </div>
              <div class="blog-meta mb-xl-20">
                <h6 class="blog-title text-light-black">Why do we choose it?</h6>
                <p class="text-light-white">It is a long established fact that a reader will be distracted by the
                  readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a
                  more-or-less normal
                  distribution of letters, as opposed to using 'Content here, content here', making it look like
                  readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their
                  default model text, and a search
                  for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved
                  over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
              </div>
              <div class="user-gallery">
                <div class="row">
                  <div class="col-md-3" *ngFor="let storygallery of storygallerypost">
                    <a href="#">
                      <img src="{{storygallery.img}}" class="img-fluid rounded mx-auto d-block full-width">
                    </a>
                  </div>
                </div>
              </div>
              <hr>
              <div class="post-details-tags-social mb-xl-20">
                <div class="row">
                  <div class="col-lg-8 col-md-6">
                    <div class="tags-box">
                      <span class="fs-18 text-light-white"><i class="fas fa-tags"></i></span>
                      <div class="tags">
                        <a routerLink="/story-detail">Rent</a>
                        <a routerLink="/story-detail">Buy</a>
                        <a routerLink="/story-detail">Sale</a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6">
                    <div class="social-media-box">
                      <ul class="custom">
                        <li><a routerLink="/story-detail" class="fb"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a routerLink="/story-detail" class="tw"><i class="fab fa-twitter"></i></a></li>
                        <li><a routerLink="/story-detail" class="gg"><i class="fab fa-google-plus-g"></i></a></li>
                        <li><a routerLink="/story-detail" class="ln"><i class="fab fa-linkedin-in"></i></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="comment-box">
                <div class="section-header-left">
                  <h6 class="text-light-black header-title">Comments</h6>
                </div>
                <div class="review-box">
                  <div class="review-user">
                    <div class="review-user-img">
                      <img src="{{commentimg1}}" class="rounded-circle" alt="#">
                      <div class="reviewer-name">
                        <p class="text-light-black fw-600">Sarra</p>
                      </div>
                    </div>
                    <div class="review-date"> <span class="text-light-white">Jan 20, 2022</span>
                    </div>
                  </div>
                  <p class="text-light-black">Delivery was fast and friendly. product was not great
                    especially the salad. Will not be ordering from again. Too many options to
                    settle for this place.</p>
                </div>
                <div class="review-box comment-reply">
                  <div class="review-user">
                    <div class="review-user-img">
                      <img src="{{commentimg2}}" class="rounded-circle" alt="#">
                      <div class="reviewer-name">
                        <p class="text-light-black fw-600">Sarra </p>
                      </div>
                    </div>
                    <div class="review-date"> <span class="text-light-white">Sep 20, 2022</span>
                    </div>
                  </div>
                  <p class="text-light-black">Delivery was fast and friendly. product was not great
                    especially the salad. Will not be ordering from again. Too many options to
                    settle for this place.</p>
                </div>
                <div class="review-box">
                  <div class="review-user">
                    <div class="review-user-img">
                      <img src="{{commentimg3}}" class="rounded-circle" alt="#">
                      <div class="reviewer-name">
                        <p class="text-light-black fw-600">Sarra</p>
                      </div>
                    </div>
                    <div class="review-date"> <span class="text-light-white">Sep 20, 2022</span>
                    </div>
                  </div>
                  <p class="text-light-black">Delivery was fast and friendly. product was not great
                    especially the salad. Will not be ordering from again. Too many options to
                    settle for this place.</p>
                </div>
                <div class="review-box comment-reply">
                  <div class="review-user">
                    <div class="review-user-img">
                      <img src="{{commentimg4}}" class="rounded-circle" alt="#">
                      <div class="reviewer-name">
                        <p class="text-light-black fw-600">Sarra</p>
                      </div>
                    </div>
                    <div class="review-date"> <span class="text-light-white">Sep 20, 2022</span>
                    </div>
                  </div>
                  <p class="text-light-black">Delivery was fast and friendly. product was not great
                    especially the salad. Will not be ordering from again. Too many options to
                    settle for this place.</p>
                </div>
              </div>
              <div class="comment-form">
                <div class="section-header-left">
                  <h6 class="text-light-black header-title">Leave a Reply</h6>
                </div>
                <form>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="text-light-black fw-600">Full Name</label>
                        <input type="text" name="#" class="form-control form-control-submit" placeholder="Full Name">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="text-light-black fw-600">Email Address</label>
                        <input type="email" name="#" class="form-control form-control-submit"
                          placeholder="Email Address">
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="text-light-black fw-600">Your Comment</label>
                        <textarea class="form-control form-control-submit" name="#" rows="6"
                          placeholder="Your Comment"></textarea>
                      </div>
                      <button type="submit" class="btn-second btn-submit full-width">Send</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <aside class="col-xl-3 col-lg-4">
        <app-story-sidebar></app-story-sidebar>
      </aside>
    </div>
  </div>
</section>
<app-newsletter2></app-newsletter2>
<app-footer></app-footer>