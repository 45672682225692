import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-story-archive',
  templateUrl: './story-archive.component.html',
  styleUrls: ['./story-archive.component.css']
})
export class StoryArchiveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  // Storyboxes
  storyboxes = [
    {img:"assets/img/story-big/story8.jpg",authorname:"Dr. Michle Colen",goalprice:"4585.00",title:"More than One Life Changed",text:"Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem."},
    {img:"assets/img/story-big/story7.jpg",authorname:"Dr. Michle Colen",goalprice:"4585.00",title:"Help for Children of the East",text:"Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem."},
    {img:"assets/img/story-big/story6.jpg",authorname:"Dr. Michle Colen",goalprice:"4585.00",title:"Help for Children of the East",text:"Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem."},
    {img:"assets/img/story-big/story5.jpg",authorname:"Dr. Michle Colen",goalprice:"4585.00",title:"More than One Life Changed",text:"Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem."},
    {img:"assets/img/story-big/story4.jpg",authorname:"Dr. Michle Colen",goalprice:"4585.00",title:"More than One Life Changed",text:"Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem."},
    {img:"assets/img/story-big/story3.jpg",authorname:"Dr. Michle Colen",goalprice:"4585.00",title:"Help for Children of the East",text:"Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem."},
    {img:"assets/img/story-big/story2.jpg",authorname:"Dr. Michle Colen",goalprice:"4585.00",title:"More than One Life Changed",text:"Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem."},
    {img:"assets/img/story-big/story1.jpg",authorname:"Dr. Michle Colen",goalprice:"4585.00",title:"More than One Life Changed",text:"Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem."},
  ];

}
