import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shop-sidebar',
  templateUrl: './shop-sidebar.component.html',
  styleUrls: ['./shop-sidebar.component.css']
})
export class ShopSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  // Top products
  topproducts = [
    { class: "pb-xl-20 mb-xl-20 u-line", img: "assets/img/shop/sp1.jpg", title: "Campaign Cap", price: "5.00" },
    { class: "pb-xl-20 mb-xl-20 u-line", img: "assets/img/shop/sp2.jpg", title: "Campaign Shirt", price: "5.25" },
    { class: "pb-xl-20 mb-xl-20 u-line", img: "assets/img/shop/sp3.jpg", title: "Obidients Hoodie", price: "15.00" },
    // { img: "assets/img/shop/sp4.jpg", title: "Brown liquid inside", price: "50.25" }
  ];
  // Brands
  brands = [
    { title: "Goldturn", count: "3" },
    { title: "Silverline", count: "6" },
    { title: "Gemtuk", count: "10" },
    { title: "Ringwing", count: "6" }
  ];
  // Weights
  weights = [
    { title: "Small" },
    { title: "Medium" },
    { title: "Large" }
  ];
  // Tags
  tags = [
    { title: "Gift items" },
    { title: "Clothing" },
    { title: "Car accessories" },
    { title: "Writing Accessories" },
    { title: "Vote" },
    { title: "Accessories" },
    // { title: "Health" },
    // { title: "Luxury" },
    // { title: "Rings" },
    // { title: "Outer wear" },
    // { title: "Luxury" },
    // { title: "Bestseller" },
    // { title: "Low Cost" }
  ];
  // Categories
  categories = [
    { name: "Children", count: "110" },
    { name: "Charity", count: "98" },
    { name: "Donation", count: "52" },
    { name: "Paintings", count: "26" },
    { name: "Antique", count: "55" },
    { name: "Handicraft", count: "48" },
    { name: "Ceramicart", count: "50" }
  ];

}
