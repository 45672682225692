<app-navbar></app-navbar>
<!-- slider -->
<section class="p-relative single-banner">
	<img src="{{bannerimg}}" class="img-fluid full-width" alt="Banner">
	<div class="transform-center">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-lg-8 align-self-center">
					<div class="right-side-content text-center">
						<h5 class="text-white">Around the world.</h5>
						<h1 class="text-white fw-600 fs-60">Help Us Make A Diffrence In This World</h1>
						<p class="text-white fw-400">Gray eel-catfish longnose whiptail catfish smalleye squaretail
							queen danio unicorn fish
							shortnose greeneye fusilier fish silver carp nibbler sharksucker tench lookdown catfishf</p>
						<a routerLink="/about-us" class="btn-solid with-line btn-big mt-20"><span>Explore<i
									class="fas fa-caret-right"></i></span></a>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="overlay overlay-bg-dark-2"></div>
</section>
<!-- slider -->
<!-- Browse by category -->
<section class="browse-cat bg-theme-primary section-padding">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-12">
				<div class="section-header-left title">
					<h3 class="text-light-black header-title">Our Candidates List </h3>
					<span class="fs-16 align-self-center"><a routerLink="/homepage3">See All</a></span>
				</div>
			</div>
			<div class="col-12">
				<swiper [config]="categoryconfig" class="category-slider swiper-container">
					<div class="swiper-wrapper">
						<div class="swiper-slide" *ngFor="let categoriespost of categoriesposts">
							<a routerLink="/story-archive" class="categories">
								<div class="icon text-custom-white bg-light-green ">
									<img src="{{ categoriespost.img }}" class="rounded-circle" alt="categories">
								</div> <span class="text-light-black cat-name">{{ categoriespost.caption }}</span>
							</a>
						</div>
					</div>
					<!-- Add Arrows -->
					<div class="swiper-button-next"></div>
					<div class="swiper-button-prev"></div>
				</swiper>
			</div>
		</div>
	</div>
</section>
<!-- Browse by category -->
<!--Latest Product-->
<section class="section-padding our-product">
	<app-latestproducts></app-latestproducts>
</section>
<!--End Latest Product-->
<!--shopping section end-->
<!-- advertisement banner-->
<section class="section-padding advertisement-banner-1 bg-banner-sponsr">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-lg-8">
				<div class="sigma-adv-content-1">
					<h6 class="sub-head">We do it for all people</h6>
					<h3 class="text-white heading">Delivering help and hope to <span
							class="text-color-primary">children</span> through sponsorship.</h3>
					<div class="ad-count ">
						<div class="countdown-box timer-white" data-countdown="2022/12/06"></div>
					</div>
				</div>
			</div>
			<div class="col-lg-4 align-self-center">
				<div class="blob play-btn ">
					<a href="https://www.youtube.com/watch?v=Fm0x3V7cOPE" #video class="popup-video"> <i
							class="fas fa-play"></i></a>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- advertisement banner-->
<!-- Hot Deals -->
<section class="ex-collection section-padding bg-theme-primary">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-12">
				<div class="section-header-left">
					<h3 class="text-light-black header-title title"> Our Hot Deals</h3>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-4" *ngFor="let hotdealpost of hotdealposts">
				<div class="story-wrapper">
					<img src="{{hotdealpost.img}}" alt="" class="full-width img-fluid mx-auto d-block">
					<div class="story-box-content story-content-wrapper">
						<span class="story-badge bg-custom-primary text-color-secondary">{{hotdealpost.badge}}</span>
						<h5><a routerLink="/donation-detail">{{hotdealpost.title}}</a></h5>
						<a routerLink="/donation-detail" class="btn btn-text btn-text-white">{{hotdealpost.linktext}}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--Hot deals End-->
<!--shopping section-->
<section class="section-padding our-product">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-12">
				<div class="section-header-left">
					<h3 class="text-light-black header-title title">Our Best Sellers</h3>
				</div>
			</div>
			<div class="col-xl-3 col-lg-4 col-md-4">
				<div class="large-product-box  p-relative">
					<div class="featured-product-box box-shadow">
						<div class="featured-pro-title">
							<h4 class="fs-22"><strong class="text-color-primary"> Vote </strong>now don't postpone</h4>
						</div>
						<div class="featured-pro-content">
							<div class="featured-pro-text">
								<h6><a routerLink="/homepage3"> Vote for a better community.</a></h6>
								<p>Lorem ipsum dolor sit amet, ctetur adipiscing elit, sed do eiusmod</p>
								<p class="price">$244</p>
							</div>
						</div>
						<div class="featured-pro-img">
							<img src="{{featuredproimg}}" alt="pro-img" class="img-fluid mx-auto d-block">
						</div>
						<div class="featured-pro-timer">
							<div class="countdown-box timer-wrap" data-countdown="2022/12/06"></div>
						</div>
						<div class="featured-pro-bottom">
							<ul>
								<li>Votes: <strong>150000 </strong></li>
								<li>Goal: <strong>200000</strong> </li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-9 col-lg-8 col-md-8">
				<div class="row">
					<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6" *ngFor="let bestseller of bestsellers">
						<div class="product-box mb-md-20">
							<div class="product-img">
								<a routerLink="/shop-detail">
									<img src="{{ bestseller.img }}" class="img-fluid full-width" alt="product-img">
								</a>
								<div class="product-badge">
									<div class="product-label {{ bestseller.labelcls }}">
										<span>{{ bestseller.label }}</span>
									</div>
								</div>
								<div class="button-group">
									<a routerLink="/wishlist" placement="left" title="" ngbTooltip="Add to wishlist"
										tabindex="-1">
										<i class="pe-7s-like"></i>
									</a>
									<a routerLink="/" placement="left" title="" ngbTooltip="Quick View">
										<span>
											<i class="pe-7s-search"></i>
										</span>
									</a>
								</div>
								<div class="cart-hover">
									<a routerLink="/shop-detail" class="btn-cart  fw-600"
										tabindex="-1">{{ bestseller.carttext }}</a>
								</div>
							</div>
							<div class="product-caption text-center">
								<div class="product-status">
									<ul class="product-raised">
										<li><strong>{{ bestseller.soldtitle }}:</strong> {{ bestseller.soldprice }}</li>
										<li><strong>{{ bestseller.goaltitle }}:</strong><span class="text-highlight">
												{{ bestseller.soldprice }}</span></li>
									</ul>
									<div class="progress">
										<div class="progress-bar progress-bar-color" style="width: 75%;"></div>
									</div>
								</div>
								<h6 class="product-title fw-500 mt-10"><a routerLink="/shop-detail"
										class="text-color-secondary">{{ bestseller.title }}</a></h6>
								<div class="product-money mt-10"> <span
										class="text-color-primary fw-600 fs-16">${{ bestseller.price }}</span>
									<span class="text-price">${{ bestseller.discountprice }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--shopping section end-->
<!-- advertisement banner-->
<section class="section-padding advertisement-banner-1 bg-banner-mission center-bg-effect">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-12">
				<div class="sigma-adv-content text-center mx-center-width">
					<h3 class="text-white">We Are In A<span class="text-white"> Mission</span> To Help Helpless</h3>
					<p class="text-white">There are many variations of passages of Lorem Ipsum available, but the
						majority have suffered alteration in some form, by injected humour.</p>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- advertisement banner-->
<!--Special offer-->
<section class="section-padding ex-collection bg-theme-primary">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-12">
				<div class="section-header-left">
					<h3 class="text-light-black header-title title">Our Donation Campaigns</h3>
				</div>
			</div>
			<div class="col-xl-3 col-lg-6 col-md-6" *ngFor="let campaignpost of campaignposts">
				<div class="sa-causes-single sa-causes-single-2">
					<div class="causes-details-wrap">
						<div class="causes-details">
							<h5><a routerLink="/donation-detail">{{ campaignpost.title }}</a></h5>
							<p>{{ campaignpost.description }}</p>
							<div class="entry-thumb mtmb-spacing">
								<img src="{{ campaignpost.img }}" alt="img" class="img-fluid full-width">
								<div class="dontaion-category"><a routerLink="/donation-detail">{{ campaignpost.category }}</a></div>
							</div>
							<div class="cause-progress">
								<div class="progress-bar" role="progressbar" aria-valuenow="17" aria-valuemin="0"
									aria-valuemax="100" style="width: 17%;">
									<span>17%</span>
								</div>
							</div>
							<div class="causes-amount">
								<div class="left">
									<p>Raised</p>
									<span>${{ campaignpost.raiseprice }}</span>
								</div>
								<div class="right">
									<p>Goal</p>
									<span>${{ campaignpost.goalprice }}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="btn-area text-center">
						<a class="btn-donation text-btn" routerLink="/donation-detail">{{ campaignpost.linktext }}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--shopping section-->
<!--shopping section-->
<section class="section-padding our-product">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-12">
				<div class="section-header-left">
					<h3 class="text-light-black header-title title"> Help us fund our campaigns</h3>
				</div>
			</div>
			<div class="col-xl-9 col-lg-8 col-md-8">
				<div class="row">
					<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6" *ngFor="let bestseller of bestsellers">
						<div class="product-box mb-md-20">
							<div class="product-img">
								<a routerLink="/shop-detail">
									<img src="{{ bestseller.img }}" class="img-fluid full-width" alt="product-img">
								</a>
								<div class="product-badge">
									<div class="product-label {{ bestseller.labelcls }}">
										<span>{{ bestseller.label }}</span>
									</div>
								</div>
								<div class="button-group">
									<a routerLink="/wishlist" placement="left" title="" ngbTooltip="Add to wishlist"
										tabindex="-1">
										<i class="pe-7s-like"></i>
									</a>
									<a routerLink="/" placement="left" title="" ngbTooltip="Quick View">
										<span>
											<i class="pe-7s-search"></i>
										</span>
									</a>
								</div>
								<div class="cart-hover">
									<a routerLink="/shop-detail" class="btn-cart  fw-600"
										tabindex="-1">{{ bestseller.carttext }}</a>
								</div>
							</div>
							<div class="product-caption text-center">
								<div class="product-status">
									<ul class="product-raised">
										<li><strong>{{ bestseller.soldtitle }}:</strong> {{ bestseller.soldprice }}</li>
										<li><strong>{{ bestseller.goaltitle }}:</strong><span class="text-highlight">
												{{ bestseller.soldprice }}</span></li>
									</ul>
									<div class="progress">
										<div class="progress-bar progress-bar-color" style="width: 75%;"></div>
									</div>
								</div>
								<h6 class="product-title fw-500 mt-10"><a routerLink="/shop-detail"
										class="text-color-secondary">{{ bestseller.title }}</a></h6>
								<div class="product-money mt-10"> <span
										class="text-color-primary fw-600 fs-16">${{ bestseller.price }}</span>
									<span class="text-price">${{ bestseller.discountprice }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-3 col-lg-4 col-md-4">
				<div class="large-product-box  p-relative">
					<div class="featured-product-box box-shadow">
						<div class="featured-pro-title">
							<h4 class="fs-22"><strong class="text-color-primary"> Vote </strong>now don't postpone</h4>
						</div>
						<div class="featured-pro-content">
							<div class="featured-pro-text">
								<h6><a routerLink="/homepage3"> Vote for a better community.</a></h6>
								<p>Lorem ipsum dolor sit amet, ctetur adipiscing elit, sed do eiusmod</p>
								<p class="price">$244</p>
							</div>
						</div>
						<div class="featured-pro-img">
							<img src="{{featuredimg}}" alt="pro-img" class="img-fluid mx-auto d-block">
						</div>
						<div class="featured-pro-timer">
							<div class="countdown-box timer-wrap" data-countdown="2022/12/06"></div>
						</div>
						<div class="featured-pro-bottom">
							<ul>
								<li>Votes: <strong>150000 </strong></li>
								<li>Goal: <strong>200000</strong> </li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--Testimonail-->
<app-testimonials></app-testimonials>
<!--Testimonail End-->
<!-- Blog section -->
<section class="section-padding blog-section bg-theme-primary">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-12">
				<div class="section-header-left title">
					<h3 class="text-light-black header-title">Our Latest News</h3>
					<span class="fs-16"><a routerLink="/blog-right">See All</a></span>
				</div>
			</div>
			<div class="col-xl-4 col-lg-3 col-md-6" *ngFor="let blogpost of blogposts">
				<article class="blog-item blog-item-box">
					<div class="blog-item-img">
						<img class="blog-img" src="{{ blogpost.img }}" alt="img">
						<ul class="blog-item-badge">
							<li *ngIf="blogpost.tag1"><a routerLink="/blog-detail">{{ blogpost.tag1 }}</a> </li>
							<li *ngIf="blogpost.tag2"><a routerLink="/blog-detail">{{ blogpost.tag2 }}</a></li>
						</ul>
					</div>
					<div class="blog-item-content">
						<h5 class="blog-item-title"><a routerLink="/blog-detail">{{ blogpost.title }}</a></h5>
						<p>{{ blogpost.description }}</p>
						<div class="blog-item-details">
							<span class="blog-item-date"><i class="fas fa-calendar-week"></i> {{ blogpost.postdate }}</span>
							<span><i class="fas fa-comment-dots"></i> {{ blogpost.postcomment }}</span>
						</div>
					</div>
				</article>
			</div>
		</div>
	</div>
</section>
<!-- END/BLOG Section -->
<app-newsletter2></app-newsletter2>
<app-footer></app-footer>
