<app-topbar></app-topbar>
<app-navbar></app-navbar>
<!-- breadcrumb -->
<!-- <app-breadcrumbs></app-breadcrumbs> -->
<!-- breadcrumb -->
<!-- Blog -->
<div class="inner-wrapper">
  <div class="container-fluid no-padding">
    <div class="row no-gutters">
      <div class="col-md-6">
        <div class="main-banner">
          <img
            src="{{ bannerImg }}"
            class="img-fluid full-width main-img rounded-0"
            alt="banner"
          />
          <div class="overlay-2 main-padding"></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="section-2 main-page main-padding bg-theme-primary">
          <div class="dontation-box">
            <div class="col-12">
              <h1 class="text-light-black fw-600">
                Everyday support comes
              </h1>
              <!-- <p>Search Your Nearest Store.</p> -->
              <div class="donation_form_right">
                <ul class="donation_form_box list-unstyled">
                  <li><a href="javascript:void(0);">$50</a></li>
                  <li><a href="javascript:void(0);">$100</a></li>
                  <li><a href="javascript:void(0);">$150</a></li>
                  <li><a href="javascript:void(0);">$200</a></li>
                  <li><a href="javascript:void(0);">$250</a></li>
                  <li><a href="javascript:void(0);">$300</a></li>
                  <li><a href="javascript:void(0);">$350</a></li>
                  <li><a href="javascript:void(0);">$400</a></li>
                  <li class="other_text"><a href="javascript:void(0);">other</a></li>
                </ul>
                <div class="donation_form_btn">
                  <a
                    href="#"
                    class="custom-btn btn-area mt-20 rounded"
                    >Donate Now <i style="font-size: 22px;" class="fa fa-angle-right"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-newsletter2></app-newsletter2>
    <app-footer></app-footer>
  </div>
</div>
