import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import 'jquery-countdown';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    var coundwn = $('.timer_wrap');
    $(coundwn).each(function () {
      (<any>$(coundwn)).countdown(coundwn.data('countdown'), function (event: { strftime: (arg0: string) => string; }) {
        $(coundwn).html(
          event.strftime('<div class="time-box"> <span>%H</span></div><div class="time-box"> <span>%M</span></div><div class="time-box"> <span>%S</span></div>')
        );
      });
    });
  }
  // Order list
  orderlistpost = [
    {img:"assets/img/shop/sp1.jpg",items:"1",id:"2354",title:"Handmade",price:"20.99"},
    {img:"assets/img/shop/sp2.jpg",items:"2",id:"2354",title:"Handmade",price:"35.50"},
    {img:"assets/img/shop/sp3.jpg",items:"5",id:"2354",title:"Handmade",price:"95.50"},
    {img:"assets/img/shop/sp4.jpg",items:"6",id:"2354",title:"Handmade",price:"130.5"},
    {img:"assets/img/shop/sp5.jpg",items:"2",id:"2354",title:"Handmade",price:"25.45"}
  ];

}
