import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shoprelated',
  templateUrl: './shoprelated.component.html',
  styleUrls: ['./shoprelated.component.css']
})
export class ShoprelatedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  // relatedshopposts
  relatedshopposts = [
    {img:"assets/img/shop/product1.jpg",tagclass:"new",tagname:"Accessories",carttext:"Add To Cart",soldprice:"45,000",goalprice:"70,000",title:"Campaign Shirt",price:"90.00",discountprice:"250.00"},
    {img:"assets/img/shop/product2.jpg",tagclass:"new",tagname:"Accessories",carttext:"Add To Cart",soldprice:"45,000",goalprice:"70,000",title:"Campaign Shirt",price:"90.00",discountprice:"250.00"},
    {img:"assets/img/shop/product3.jpg",tagclass:"new",tagname:"Accessories",carttext:"Add To Cart",soldprice:"45,000",goalprice:"70,000",title:"Campaign Shirt",price:"90.00",discountprice:"250.00"},
    {img:"assets/img/shop/product4.jpg",tagclass:"new",tagname:"Accessories",carttext:"Add To Cart",soldprice:"45,000",goalprice:"70,000",title:"Campaign Shirt",price:"90.00",discountprice:"250.00"}
  ];

}
