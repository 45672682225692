<div class="custom-pagination align-item-center">
    <nav aria-label="Page navigation example">
        <ul class="pagination">
            <li class="page-item">
                <a class="page-link" routerLink="/shop" aria-label="Previous"> <span
                        aria-hidden="true">«</span>
                    <span class="sr-only">Previous</span>
                </a>
            </li>
            <li class="page-item"><a class="page-link" routerLink="/shop">1</a>
            </li>
            <li class="page-item active"><a class="page-link" routerLink="/shop">2</a>
            </li>
            <li class="page-item"><a class="page-link" routerLink="/shop">3</a>
            </li>
            <li class="page-item">
                <a class="page-link" routerLink="/shop" aria-label="Next"> <span
                        aria-hidden="true">»</span>
                    <span class="sr-only">Next</span>
                </a>
            </li>
        </ul>
    </nav>
</div>
