import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shop-right',
  templateUrl: './shop-right.component.html',
  styleUrls: ['./shop-right.component.css']
})
export class ShopRightComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  shopgridboxes = [
    {img:"assets/img/shop/product1.jpg",tagclass:"new",tagname:"Accessories",carttext:"Add To Cart",soldprice:"45,000",goalprice:"70,000",title:"Campaign Shirt",price:"90.00",discountprice:"250.00"},
    {img:"assets/img/shop/product2.jpg",tagclass:"new",tagname:"Accessories",carttext:"Add To Cart",soldprice:"45,000",goalprice:"70,000",title:"Campaign Shirt",price:"90.00",discountprice:"250.00"},
    {img:"assets/img/shop/product4.jpg",tagclass:"new",tagname:"Accessories",carttext:"Add To Cart",soldprice:"45,000",goalprice:"70,000",title:"Campaign Shirt",price:"90.00",discountprice:"250.00"},
    {img:"assets/img/shop/product3.jpg",tagclass:"new",tagname:"Accessories",carttext:"Add To Cart",soldprice:"45,000",goalprice:"70,000",title:"Campaign Shirt",price:"90.00",discountprice:"250.00"},
    {img:"assets/img/shop/product4.jpg",tagclass:"new",tagname:"Accessories",carttext:"Add To Cart",soldprice:"45,000",goalprice:"70,000",title:"Campaign Shirt",price:"90.00",discountprice:"250.00"},
    {img:"assets/img/shop/product3.jpg",tagclass:"new",tagname:"Accessories",carttext:"Add To Cart",soldprice:"45,000",goalprice:"70,000",title:"Campaign Shirt",price:"90.00",discountprice:"250.00"},
    {img:"assets/img/shop/product1.jpg",tagclass:"new",tagname:"Accessories",carttext:"Add To Cart",soldprice:"45,000",goalprice:"70,000",title:"Campaign Shirt",price:"90.00",discountprice:"250.00"},
    {img:"assets/img/shop/product2.jpg",tagclass:"new",tagname:"Accessories",carttext:"Add To Cart",soldprice:"45,000",goalprice:"70,000",title:"Campaign Shirt",price:"90.00",discountprice:"250.00"},
    {img:"assets/img/shop/product3.jpg",tagclass:"new",tagname:"Accessories",carttext:"Add To Cart",soldprice:"45,000",goalprice:"70,000",title:"Campaign Shirt",price:"90.00",discountprice:"250.00"},
    {img:"assets/img/shop/product4.jpg",tagclass:"new",tagname:"Accessories",carttext:"Add To Cart",soldprice:"45,000",goalprice:"70,000",title:"Campaign Shirt",price:"90.00",discountprice:"250.00"},
    {img:"assets/img/shop/product2.jpg",tagclass:"new",tagname:"Accessories",carttext:"Add To Cart",soldprice:"45,000",goalprice:"70,000",title:"Campaign Shirt",price:"90.00",discountprice:"250.00"},
    {img:"assets/img/shop/product4.jpg",tagclass:"new",tagname:"Accessories",carttext:"Add To Cart",soldprice:"45,000",goalprice:"70,000",title:"Campaign Shirt",price:"90.00",discountprice:"250.00"},
    {img:"assets/img/shop/product1.jpg",tagclass:"new",tagname:"Accessories",carttext:"Add To Cart",soldprice:"45,000",goalprice:"70,000",title:"Campaign Shirt",price:"90.00",discountprice:"250.00"},
    {img:"assets/img/shop/product3.jpg",tagclass:"new",tagname:"Accessories",carttext:"Add To Cart",soldprice:"45,000",goalprice:"70,000",title:"Campaign Shirt",price:"90.00",discountprice:"250.00"},
    {img:"assets/img/shop/product4.jpg",tagclass:"new",tagname:"Accessories",carttext:"Add To Cart",soldprice:"45,000",goalprice:"70,000",title:"Campaign Shirt",price:"90.00",discountprice:"250.00"},
    {img:"assets/img/shop/product1.jpg",tagclass:"new",tagname:"Accessories",carttext:"Add To Cart",soldprice:"45,000",goalprice:"70,000",title:"Campaign Shirt",price:"90.00",discountprice:"250.00"}
  ];

}
