import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-latestproducts',
  templateUrl: './latestproducts.component.html',
  styleUrls: ['./latestproducts.component.css']
})
export class LatestproductsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  
  // ourbestsellers post
  ourbestsellers = [
    { img: "assets/img/shop/product5.jpg", labelcls: "high", label: "Antique", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product7.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },   
    { img: "assets/img/shop/product8.jpg", labelcls: "Handmade", label: "Handmade", soldprice: "45,000", goalprice: "70,000", title: "Metal Artwork", price: "90.00", discountprice: "250.00" },    
    { img: "assets/img/shop/product6.jpg", labelcls: "high", label: "Antique", soldprice: "45,000", goalprice: "70,000", title: "Voting Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product9.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product10.jpg", labelcls: "Ceramicart", label: "Ceramicart", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },   
    { img: "assets/img/shop/product5.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Seal", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product11.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Intricate Calendar", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product9.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product7.jpg", labelcls: "Ceramicart", label: "Ceramicart", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },   
    { img: "assets/img/shop/product8.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Seal", price: "90.00", discountprice: "250.00" },   
    { img: "assets/img/shop/product7.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Intricate Calendar", price: "90.00", discountprice: "250.00" }
  ];
  // mostrated post
  mostratedposts = [
    { img: "assets/img/shop/product9.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product11.jpg", labelcls: "high", label: "Antique", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product1.jpg", labelcls: "Handmade", label: "Handmade", soldprice: "45,000", goalprice: "70,000", title: "Metal Artwork", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product11.jpg", labelcls: "high", label: "Antique", soldprice: "45,000", goalprice: "70,000", title: "Voting Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product10.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product9.jpg", labelcls: "Ceramicart", label: "Ceramicart", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product8.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Seal", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product7.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Intricate Calendar", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product6.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },  
    { img: "assets/img/shop/product6.jpg", labelcls: "Ceramicart", label: "Ceramicart", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product5.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Seal", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product9.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Intricate Calendar", price: "90.00", discountprice: "250.00" }
  ];
  mostsellingpost = [
    { img: "assets/img/shop/product11.jpg", labelcls: "high", label: "Antique", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product10.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product8.jpg", labelcls: "Handmade", label: "Handmade", soldprice: "45,000", goalprice: "70,000", title: "Metal Artwork", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product11.jpg", labelcls: "high", label: "Antique", soldprice: "45,000", goalprice: "70,000", title: "Voting Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product10.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product9.jpg", labelcls: "Ceramicart", label: "Ceramicart", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product8.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Seal", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product7.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Intricate Calendar", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product6.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product5.jpg", labelcls: "Ceramicart", label: "Ceramicart", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product9.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Seal", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product11.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Intricate Calendar", price: "90.00", discountprice: "250.00" }
  ];

}
