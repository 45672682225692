<!-- Navigation -->
<header class="header" [ngClass]="windowScrolled ? 'sticky':''">
  <div class="container-fluid custom-container">
    <div class="row">
      <div class="col-12">
        <div class="navigation">
          <div class="logo">
            <a routerLink="/">
              <img src="{{ logo }}" class="image-fit" alt="logo">
            </a>
          </div>
          <div class="main-navigation" [ngClass]="check ? '' : 'active'">
            <nav>
              <ul class="main-menu">
                <li class="menu-item">
                  <a routerLink="/" [ngClass]="windowScrolled ? 'text-light-black':'text-custom-white'" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a>
                  <!-- <ul class="sub-menu">
                    <li class="menu-item"> <a routerLink="/" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">HomePage 1</a>
                    </li>
                    <li class="menu-item"> <a routerLink="/homepage2" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Homepage 2</a>
                    </li>
                    <li class="menu-item"> <a routerLink="/homepage3" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Homepage 3</a>
                    </li>
                    <li class="menu-item"> <a routerLink="/homepage4" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Homepage 4</a>
                    </li>
                    <li class="menu-item"> <a routerLink="/homepage5" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Homepage 5</a>
                    </li>
                  </ul> -->
                </li>
                <li class="menu-item"> <a routerLink="/about-us" [ngClass]="windowScrolled ? 'text-light-black':'text-custom-white'" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a>
                </li>
                <li class="menu-item">
                  <a routerLink="/shop" [ngClass]="windowScrolled ? 'text-light-black':'text-custom-white'" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Shop</a>
                  <!-- <ul class="sub-menu">
                    <li class="menu-item">
                      <a routerLink="/shop" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Shop</a>
                      <ul class="sub-menu">
                        <li class="menu-item"> <a routerLink="/shop" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Left Sidebar</a>
                        </li>
                        <li class="menu-item"> <a routerLink="/shop-right" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Right Sidebar</a>
                        </li>
                      </ul>
                    </li>
                    <li class="menu-item"> <a routerLink="/shop-detail" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Shop Detail</a>
                    </li>
                  </ul> -->
                </li>
                <!-- <li class="menu-item menu-item-has-children">
                  <a href="#" [ngClass]="windowScrolled ? 'text-light-black':'text-custom-white'" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pages</a>
                  <ul class="sub-menu">
                    <li class="menu-item"> <a routerLink="/donation-archive" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Donation Archive</a>
                    </li>
                    <li class="menu-item"> <a routerLink="/donation-detail" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Donation Details</a>
                    </li>
                    <li class="menu-item"> <a routerLink="/wishlist" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Wishlist</a>
                    </li>
                    <li class="menu-item"> <a routerLink="/checkout" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Check Out</a>
                    </li>
                    <li class="menu-item"> <a routerLink="/order" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Order</a>
                    </li>
                    <li class="menu-item"> <a routerLink="/login" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Log In</a>
                    </li>
                    <li class="menu-item"> <a routerLink="/register" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Register</a>
                    </li>
                    <li class="menu-item"> <a routerLink="/contact" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a>
                    </li>
                  </ul>
                </li> -->
                <!-- <li class="menu-item menu-item-has-children">
                  <a routerLink="/blog-left" [ngClass]="windowScrolled ? 'text-light-black':'text-custom-white'" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a>
                  <ul class="sub-menu">
                    <li class="menu-item"> <a routerLink="/blog-right" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Right</a>
                    </li>
                    <li class="menu-item"> <a routerLink="/blog-left" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Left</a>
                    </li>
                    <li class="menu-item"> <a routerLink="/blog-detail" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Detail</a>
                    </li>
                  </ul>
                </li>
                <li class="menu-item menu-item-has-children">
                  <a href="#" [ngClass]="windowScrolled ? 'text-light-black':'text-custom-white'" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Story</a>
                  <ul class="sub-menu">
                    <li class="menu-item"> <a routerLink="/story-archive" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Story Archive</a>
                    </li>
                    <li class="menu-item"> <a routerLink="/story-detail" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Story Details</a>
                    </li>
                  </ul>
                </li> -->
              </ul>
            </nav>
          </div>
          <div class="right-side-navigation">
            <ul>
              <li class="hamburger-menu" (click)="hamburgerbtn()">
                <a href="javascript:void(0)" class="menu-btn" [ngClass]="check ? '' : 'active'"> <span></span>
                  <span></span>
                  <span></span>
                </a>
              </li>
              <!-- <li class="user-details">
                <a href="javascript:void(0)"> <i class="pe-7s-user"></i> <span>Hi, Kate</span>
                </a>
              </li> -->
              <!-- <li class="wishlist"> <a href="javascript:void(0)" class="text-dark-red"><i class="pe-7s-bell"></i></a>
                <span class="cart">3</span>
              </li> -->
              <li class="cart"> <a href="javascript:void(0)" class="text-custom-white">cart</a>
                <span class="cart">0</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="main-sec"></div>
