<app-topbar></app-topbar>
<app-navbar></app-navbar>
<!-- breadcrumb -->
<app-breadcrumbs></app-breadcrumbs>
<!-- breadcrumb -->
<!--product Start-->
<section class="section-padding our-product bg-light-white">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-xl-9 col-lg-8 mb-md-40">
				<div class="full-width">
					<div class="row">
						<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6" *ngFor="let shopgrid of shopgridboxes">
							<div class="product-box mb-md-20">
								<div class="product-img">
									<a routerLink="/shop-detail">
										<img src="{{ shopgrid.img }}" class="img-fluid full-width" alt="product-img">
									</a>
									<div class="product-badge">
										<div class="product-label {{ shopgrid.tagclass }}">
											<span>{{ shopgrid.tagname }}</span>
										</div>
									</div>
									<div class="button-group">
										<a routerLink="/wishlist" placement="left" title="" ngbTooltip="Add to wishlist"
											tabindex="-1">
											<i class="pe-7s-like"></i>
										</a>
										<a routerLink="/" placement="left" title="" ngbTooltip="Quick View">
											<span>
												<i class="pe-7s-search"></i>
											</span>
										</a>
									</div>
									<div class="cart-hover">
										<a routerLink="/shop-detail" class="btn-cart  fw-600"
											tabindex="-1">{{ shopgrid.carttext }}</a>
									</div>
								</div>
								<div class="product-caption text-center">
									<div class="product-status">
										<ul class="product-raised">
											<li><strong>Sold:</strong> {{ shopgrid.soldprice }}</li>
											<li><strong>Goal:</strong><span class="text-highlight">
													{{ shopgrid.goalprice }}</span></li>
										</ul>
										<div class="progress">
											<div class="progress-bar progress-bar-color" style="width: 75%;"></div>
										</div>
									</div>
									<h6 class="product-title fw-500 mt-10"><a routerLink="/shop-detail"
											class="text-color-secondary">{{ shopgrid.title }}</a></h6>
									<div class="product-money mt-10"> <span
											class="text-color-primary fw-600 fs-16">${{ shopgrid.price }}</span>
										<span class="text-price">${{ shopgrid.discountprice }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Pagination -->
				<app-pagination></app-pagination>
			</div>
			<div class="col-xl-3 col-lg-4">
				<app-shop-sidebar></app-shop-sidebar>
			</div>
		</div>
	</div>
</section>
<!--Product-end-->
<app-newsletter2></app-newsletter2>
<app-footer></app-footer>
