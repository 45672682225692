import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import 'jquery-countdown';

@Component({
  selector: 'app-homepage5',
  templateUrl: './homepage5.component.html',
  styleUrls: ['./homepage5.component.css']
})
export class Homepage5Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
    var coundwn = $('.timer-wrap');
    $(coundwn).each(function () {
      (<any>$(coundwn)).countdown(coundwn.data('countdown'), function (event: { strftime: (arg0: string) => string; }) {
        $(coundwn).html(
          event.strftime('<div class="counter-box"> <span>%D</span></div><div class="counter-box"> <span>%H</span></div><div class="counter-box"> <span>%M</span></div><div class="counter-box"> <span>%S</span></div>')
        );
      });
    });
  }
  bannerimg = "assets/img/slider.jpg";
  featuredproimg = "assets/img/shop/featured1.jpg";

  // Shop Posts
  shopposts = [
    { img: "assets/img/shop/product1.jpg", label1cls: "new", label1: "Accessories", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Campaign Shirt", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product2.jpg", label1cls: "new", label2cls: "discount", label1: "Accessories", label2: "25%", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Customized Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product3.jpg", label2cls: "discount", label3cls: "nonveg", label2: "25%", label3: "Wears", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Campaign Bag", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product4.jpg", label1cls: "new", label1: "Accessories", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Labour Sticker", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product2.jpg", label3cls: "nonveg", label3: "Wears", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Customized Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product4.jpg", label1cls: "new", label1: "Accessories", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Customized Pen", price: "90.00", discountprice: "250.00" }
  ];
  // hot deal
  hotdeals = [
    {img:"assets/img/shop/featured5.jpg",badge:"Enviroment",title:"More than One Life Changed",linktext:"Read More"},
    {img:"assets/img/shop/featured8.jpg",badge:"Enviroment",title:"More than One Life Changed",linktext:"Read More"},
    {img:"assets/img/shop/featured7.jpg",badge:"Enviroment",title:"More than One Life Changed",linktext:"Read More"},
    {img:"assets/img/shop/featured6.jpg",badge:"Enviroment",title:"More than One Life Changed",linktext:"Read More"}
  ];
  // Bestsellers Posts
  bestsellers = [
    { img: "assets/img/shop/product5.jpg", labelcls: "high", label: "Antique", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product6.jpg", labelcls: "uni", label: "Paintings", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product7.jpg", labelcls: "Handmade", label: "Handmade", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Metal Artwork", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product8.jpg", labelcls: "high", label: "Antique", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Voting Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product9.jpg", labelcls: "kids", label: "Handicrafts", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product10.jpg", labelcls: "Ceramicart", label: "Ceramicart", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product11.jpg", labelcls: "uni", label: "Paintings", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "USA Seal", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product5.jpg", labelcls: "kids", label: "Handicrafts", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Intricate Calendar", price: "90.00", discountprice: "250.00" }
  ];
  // hot deal
  topdeals = [
    {img:"assets/img/shop/featured5.jpg",badge:"Enviroment",title:"More than One Life Changed",linktext:"Read More"},
    {img:"assets/img/shop/featured6.jpg",badge:"Enviroment",title:"More than One Life Changed",linktext:"Read More"}
  ];

}
