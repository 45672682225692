import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-right',
  templateUrl: './blog-right.component.html',
  styleUrls: ['./blog-right.component.css']
})
export class BlogRightComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  // Blog boxes
  blogboxes = [
    {img:"assets/img/blog/blog4.jpg",tag1:"Water",tag2:"Health",title:"Save the American's Role in Fight Against Malnutrition Hailed",text:"Sharksucker sea toad candiru rocket danio tilefish stingray deepwater stingray Sacramento splittail canthigaster",postdate:"23 Jan' 19",commentcount:"501"},
    {img:"assets/img/blog/blog3.jpg",tag1:"Water",tag2:"Health",title:"Save the American's Role in Fight Against Malnutrition Hailed",text:"Sharksucker sea toad candiru rocket danio tilefish stingray deepwater stingray Sacramento splittail canthigaster",postdate:"23 Jan' 19",commentcount:"501"},
    {img:"assets/img/blog/blog2.jpg",tag1:"Water",tag2:"Health",title:"Save the American's Role in Fight Against Malnutrition Hailed",text:"Sharksucker sea toad candiru rocket danio tilefish stingray deepwater stingray Sacramento splittail canthigaster",postdate:"23 Jan' 19",commentcount:"501"},
    {img:"assets/img/blog/blog1.jpg",tag1:"Water",tag2:"Health",title:"Save the American's Role in Fight Against Malnutrition Hailed",text:"Sharksucker sea toad candiru rocket danio tilefish stingray deepwater stingray Sacramento splittail canthigaster",postdate:"23 Jan' 19",commentcount:"501"},
    {img:"assets/img/blog/blog5.jpg",tag1:"Water",tag2:"Health",title:"Save the American's Role in Fight Against Malnutrition Hailed",text:"Sharksucker sea toad candiru rocket danio tilefish stingray deepwater stingray Sacramento splittail canthigaster",postdate:"23 Jan' 19",commentcount:"501"},
    {img:"assets/img/blog/blog4.jpg",tag1:"Water",tag2:"Health",title:"Save the American's Role in Fight Against Malnutrition Hailed",text:"Sharksucker sea toad candiru rocket danio tilefish stingray deepwater stingray Sacramento splittail canthigaster",postdate:"23 Jan' 19",commentcount:"501"},
    {img:"assets/img/blog/blog3.jpg",tag1:"Water",tag2:"Health",title:"Save the American's Role in Fight Against Malnutrition Hailed",text:"Sharksucker sea toad candiru rocket danio tilefish stingray deepwater stingray Sacramento splittail canthigaster",postdate:"23 Jan' 19",commentcount:"501"},
    {img:"assets/img/blog/blog2.jpg",tag1:"Water",tag2:"Health",title:"Save the American's Role in Fight Against Malnutrition Hailed",text:"Sharksucker sea toad candiru rocket danio tilefish stingray deepwater stingray Sacramento splittail canthigaster",postdate:"23 Jan' 19",commentcount:"501"},
    {img:"assets/img/blog/blog1.jpg",tag1:"Water",tag2:"Health",title:"Save the American's Role in Fight Against Malnutrition Hailed",text:"Sharksucker sea toad candiru rocket danio tilefish stingray deepwater stingray Sacramento splittail canthigaster",postdate:"23 Jan' 19",commentcount:"501"}
  ];

}
