import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import 'jquery-countdown';

@Component({
  selector: 'app-homepage2',
  templateUrl: './homepage2.component.html',
  styleUrls: ['./homepage2.component.css'],
})
export class Homepage2Component implements OnInit {
  windowScrolled: boolean | undefined;
  constructor() {}


  @ViewChild('video') videoElement: ElementRef | any;

  // @HostListener("window:scroll", ["$event"])
  // onWindowScroll() {
  //   this.windowScrolled = window.scrollY > 100;

  //   console.log(window.pageYOffset);
  //   var demo_video: any = document.getElementById("demo_video");
  //   var how_to_video: any = document.getElementById("how_to_video");

  //   if (window.pageYOffset > 400 && window.pageYOffset < 1000) {
  //       demo_video.muted = true;
  //       demo_video.pause();
  //       how_to_video.play();
  //   }
  // }

  ngOnInit(): void {
    // COunter
    // var coundwn = $('.sigma_countdown-timer-white');
    // $(coundwn).each(function () {
    //   (<any>$(coundwn)).countdown(
    //     coundwn.data('countdown'),
    //     function (event: { strftime: (arg0: string) => string }) {
    //       $(coundwn).html(
    //         event.strftime(
    //           '<div class="counter-box"> <span>%D</span>Days</div><div class="counter-box"> <span>%H</span>Hr</div><div class="counter-box"> <span>%M</span>Min</div><div class="counter-box"> <span>%S</span>Sec</div>'
    //         )
    //       );
    //     }
    //   );
    // });

    // var coundwn2 = $('.sigma_countdown-timer');
    // $(coundwn2).each(function () {
    //   (<any>$(coundwn2)).countdown(
    //     coundwn2.data('countdown'),
    //     function (event: { strftime: (arg0: string) => string }) {
    //       $(coundwn2).html(
    //         event.strftime(
    //           '<div class="counter-box"> <span>%D</span>Days</div><div class="counter-box"> <span>%H</span>Hr</div><div class="counter-box"> <span>%M</span>Min</div><div class="counter-box"> <span>%S</span>Sec</div>'
    //         )
    //       );
    //     }
    //   );
    // });
  }
  servicetypeposts = [
    // { parentclass: "bg-custom-1 border-sm-bottom", img: "assets/img/help.svg", title: "Participate", subtitle: "As a Volunteers" },
    { parentclass: "bg-custom-2 border-sm-bottom", img: "assets/img/money.svg", title: "Be a Hand", subtitle: "As a Donor" }
    // { parentclass: "bg-custom-3 border-sm-bottom", img: "assets/img/investor.svg", title: "Participate", subtitle: "As a Voter" },
    // { parentclass: "bg-custom-4", img: "assets/img/cash.svg", title: "Be a Part", subtitle: "In our Mission" },
  ];

  // videos
  obivideo = 'assets/img/peter-obi-lp-rally.mp4';
  dattivideo = 'assets/img/ahmed-datti-bbc-hausa.mp4';
  volunteersvideo = 'assets/img/peter-obi volunteers.mp4';

  introimg = 'assets/img/home-2-into.jpg';
  party_logo = 'assets/img/lp-logo-full.jpeg';
  obi_picture_1 = 'assets/img/people/obi-transparent.png';
  datti_picture_1 = 'assets/img/people/datti-transparent.png';
  featuredproimg = 'assets/img/shop/featured.jpg';
  // Shop Posts
  shopposts = [
    {
      images: ['assets/img/shop/product1.jpg'],
      label1cls: 'new',
      label1: 'Accessories',
      carttext: 'Add To Cart',
      soldtitle: 'Sold',
      soldprice: '45,000',
      goaltitle: 'Goal',
      goalprice: '70,000',
      title: 'Campaign Shirt',
      price: '90.00',
      discountprice: '250.00',
    },

    {
      images: ['assets/img/shop/product2.jpg'],
      label1cls: 'new',
      label2cls: 'discount',
      label1: 'Accessories',
      label2: '25%',
      carttext: 'Add To Cart',
      soldtitle: 'Sold',
      soldprice: '45,000',
      goaltitle: 'Goal',
      goalprice: '70,000',
      title: 'Customized Pen',
      price: '90.00',
      discountprice: '250.00',
    },

    {
      img: 'assets/img/shop/product3.jpg',
      label2cls: 'discount',
      label3cls: 'nonveg',
      label2: '25%',
      label3: 'Wears',
      carttext: 'Add To Cart',
      soldtitle: 'Sold',
      soldprice: '45,000',
      goaltitle: 'Goal',
      goalprice: '70,000',
      title: 'Campaign Bag',
      price: '90.00',
      discountprice: '250.00',
    },

    {
      img: 'assets/img/shop/product4.jpg',
      label1cls: 'new',
      label1: 'Accessories',
      carttext: 'Add To Cart',
      soldtitle: 'Sold',
      soldprice: '45,000',
      goaltitle: 'Goal',
      goalprice: '70,000',
      title: 'Labour Sticker',
      price: '90.00',
      discountprice: '250.00',
    },

    {
      img: 'assets/img/shop/product2.jpg',
      label3cls: 'nonveg',
      label3: 'Wears',
      carttext: 'Add To Cart',
      soldtitle: 'Sold',
      soldprice: '45,000',
      goaltitle: 'Goal',
      goalprice: '70,000',
      title: 'Customized Pen',
      price: '90.00',
      discountprice: '250.00',
    },

    {
      img: 'assets/img/shop/product1.jpg',
      label1cls: 'new',
      label1: 'Accessories',
      carttext: 'Add To Cart',
      soldtitle: 'Sold',
      soldprice: '45,000',
      goaltitle: 'Goal',
      goalprice: '70,000',
      title: 'Customized Pen',
      price: '90.00',
      discountprice: '250.00',
    },
  ];
  // icon posts
  iconposts = [
    { icon: 'assets/img/help.svg' },
    { icon: 'assets/img/help.svg' },
    { icon: 'assets/img/help.svg' },
    { icon: 'assets/img/help.svg' },
    { icon: 'assets/img/help.svg' },
  ];
  // Community post
  communityposts = [
    {
      colclass: 'col-lg-4',
      img: 'assets/img/story-big/about-1.jpg',
      badge: 'Enviroment',
      title: 'More than One Life Changed',
      linktext: 'Read More',
    },
    {
      colclass: 'col-lg-4',
      img: 'assets/img/story-big/about-2.jpg',
      badge: 'Education',
      title: 'Help for Children of the East',
      linktext: 'Read More',
    },
    {
      colclass: 'col-lg-4',
      img: 'assets/img/story-big/about-3.jpg',
      badge: 'Food & Meals',
      title: 'More than One Life Changed',
      linktext: 'Read More',
    },
    {
      colclass: 'col-lg-6',
      img: 'assets/img/story-big/about-6.jpg',
      badge: 'Finance',
      title: 'Help for Children of the East',
      linktext: 'Read More',
    },
    {
      colclass: 'col-lg-6',
      img: 'assets/img/story-big/about-7.jpg',
      badge: 'Education',
      title: 'More than One Life Changed',
      linktext: 'Read More',
    },
  ];
  // Campaigns post
  campaignposts = [
    {
      title: 'Help our Veterans come back',
      description:
        'Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem.',
      img: 'assets/img/donation/article1.jpg',
      category: 'Education',
      raiseprice: '4585.00',
      goalprice: '4585.00',
      linktext: 'Donate Now',
    },
    {
      title: 'Help our Veterans come back',
      description:
        'Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem.',
      img: 'assets/img/donation/article3.jpg',
      category: 'Health',
      raiseprice: '4585.00',
      goalprice: '4585.00',
      linktext: 'Donate Now',
    },
    {
      title: 'Help our Veterans come back',
      description:
        'Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem.',
      img: 'assets/img/donation/article2.jpg',
      category: 'Hygine',
      raiseprice: '4585.00',
      goalprice: '4585.00',
      linktext: 'Donate Now',
    },
    {
      title: 'Help our Veterans come back',
      description:
        'Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem.',
      img: 'assets/img/donation/article5.jpg',
      category: 'Hygine',
      raiseprice: '4585.00',
      goalprice: '4585.00',
      linktext: 'Donate Now',
    },
  ];
  // Bestsellers Posts
  bestsellers = [
    {
      img: 'assets/img/shop/product5.jpg',
      labelcls: 'high',
      label: 'Antique',
      carttext: 'Add To Cart',
      soldtitle: 'Sold',
      soldprice: '45,000',
      goaltitle: 'Goal',
      goalprice: '70,000',
      title: 'Luxury Pen',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product6.jpg',
      labelcls: 'uni',
      label: 'Paintings',
      carttext: 'Add To Cart',
      soldtitle: 'Sold',
      soldprice: '45,000',
      goaltitle: 'Goal',
      goalprice: '70,000',
      title: 'USA Map Pin',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product7.jpg',
      labelcls: 'Handmade',
      label: 'Handmade',
      carttext: 'Add To Cart',
      soldtitle: 'Sold',
      soldprice: '45,000',
      goaltitle: 'Goal',
      goalprice: '70,000',
      title: 'Metal Artwork',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product8.jpg',
      labelcls: 'high',
      label: 'Antique',
      carttext: 'Add To Cart',
      soldtitle: 'Sold',
      soldprice: '45,000',
      goaltitle: 'Goal',
      goalprice: '70,000',
      title: 'Voting Pin',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product9.jpg',
      labelcls: 'kids',
      label: 'Handicrafts',
      carttext: 'Add To Cart',
      soldtitle: 'Sold',
      soldprice: '45,000',
      goaltitle: 'Goal',
      goalprice: '70,000',
      title: 'Luxury Pen',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product10.jpg',
      labelcls: 'Ceramicart',
      label: 'Ceramicart',
      carttext: 'Add To Cart',
      soldtitle: 'Sold',
      soldprice: '45,000',
      goaltitle: 'Goal',
      goalprice: '70,000',
      title: 'USA Map Pin',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product11.jpg',
      labelcls: 'uni',
      label: 'Paintings',
      carttext: 'Add To Cart',
      soldtitle: 'Sold',
      soldprice: '45,000',
      goaltitle: 'Goal',
      goalprice: '70,000',
      title: 'USA Seal',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product5.jpg',
      labelcls: 'kids',
      label: 'Handicrafts',
      carttext: 'Add To Cart',
      soldtitle: 'Sold',
      soldprice: '45,000',
      goaltitle: 'Goal',
      goalprice: '70,000',
      title: 'Intricate Calendar',
      price: '90.00',
      discountprice: '250.00',
    },
  ];
  // ourbestsellers post
  ourbestsellers = [
    {
      img: 'assets/img/shop/product5.jpg',
      labelcls: 'high',
      label: 'Antique',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Luxury Pen',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product7.jpg',
      labelcls: 'uni',
      label: 'Paintings',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'USA Map Pin',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product8.jpg',
      labelcls: 'Handmade',
      label: 'Handmade',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Metal Artwork',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product6.jpg',
      labelcls: 'high',
      label: 'Antique',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Voting Pin',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product9.jpg',
      labelcls: 'kids',
      label: 'Handicrafts',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Luxury Pen',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product10.jpg',
      labelcls: 'Ceramicart',
      label: 'Ceramicart',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'USA Map Pin',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product5.jpg',
      labelcls: 'uni',
      label: 'Paintings',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'USA Seal',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product11.jpg',
      labelcls: 'kids',
      label: 'Handicrafts',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Intricate Calendar',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product9.jpg',
      labelcls: 'kids',
      label: 'Handicrafts',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Luxury Pen',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product7.jpg',
      labelcls: 'Ceramicart',
      label: 'Ceramicart',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'USA Map Pin',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product8.jpg',
      labelcls: 'uni',
      label: 'Paintings',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'USA Seal',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product7.jpg',
      labelcls: 'kids',
      label: 'Handicrafts',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Intricate Calendar',
      price: '90.00',
      discountprice: '250.00',
    },
  ];
  // mostrated post
  mostratedposts = [
    {
      img: 'assets/img/shop/product9.jpg',
      labelcls: 'uni',
      label: 'Paintings',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'USA Map Pin',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product11.jpg',
      labelcls: 'high',
      label: 'Antique',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Luxury Pen',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product1.jpg',
      labelcls: 'Handmade',
      label: 'Handmade',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Metal Artwork',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product11.jpg',
      labelcls: 'high',
      label: 'Antique',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Voting Pin',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product10.jpg',
      labelcls: 'kids',
      label: 'Handicrafts',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Luxury Pen',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product9.jpg',
      labelcls: 'Ceramicart',
      label: 'Ceramicart',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'USA Map Pin',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product8.jpg',
      labelcls: 'uni',
      label: 'Paintings',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'USA Seal',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product7.jpg',
      labelcls: 'kids',
      label: 'Handicrafts',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Intricate Calendar',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product6.jpg',
      labelcls: 'kids',
      label: 'Handicrafts',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Luxury Pen',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product6.jpg',
      labelcls: 'Ceramicart',
      label: 'Ceramicart',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'USA Map Pin',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product5.jpg',
      labelcls: 'uni',
      label: 'Paintings',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'USA Seal',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product9.jpg',
      labelcls: 'kids',
      label: 'Handicrafts',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Intricate Calendar',
      price: '90.00',
      discountprice: '250.00',
    },
  ];
  mostsellingpost = [
    {
      img: 'assets/img/shop/product11.jpg',
      labelcls: 'high',
      label: 'Antique',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Luxury Pen',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product10.jpg',
      labelcls: 'uni',
      label: 'Paintings',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'USA Map Pin',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product8.jpg',
      labelcls: 'Handmade',
      label: 'Handmade',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Metal Artwork',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product11.jpg',
      labelcls: 'high',
      label: 'Antique',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Voting Pin',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product10.jpg',
      labelcls: 'kids',
      label: 'Handicrafts',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Luxury Pen',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product9.jpg',
      labelcls: 'Ceramicart',
      label: 'Ceramicart',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'USA Map Pin',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product8.jpg',
      labelcls: 'uni',
      label: 'Paintings',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'USA Seal',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product7.jpg',
      labelcls: 'kids',
      label: 'Handicrafts',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Intricate Calendar',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product6.jpg',
      labelcls: 'kids',
      label: 'Handicrafts',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Luxury Pen',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product5.jpg',
      labelcls: 'Ceramicart',
      label: 'Ceramicart',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'USA Map Pin',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product9.jpg',
      labelcls: 'uni',
      label: 'Paintings',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'USA Seal',
      price: '90.00',
      discountprice: '250.00',
    },
    {
      img: 'assets/img/shop/product11.jpg',
      labelcls: 'kids',
      label: 'Handicrafts',
      soldprice: '45,000',
      goalprice: '70,000',
      title: 'Intricate Calendar',
      price: '90.00',
      discountprice: '250.00',
    },
  ];
}
