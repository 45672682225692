<breadcrumb #parent >
  <div class="breadcrumb-area">
    <div class="overlay overlay-bg"></div>
    <div class="container">
      <div class="breadcrumb-content">
        <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
          <h2>{{ route.displayName }}</h2>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li *ngIf="!route.terminal" class="breadcrumb-item">
              <a href="" [routerLink]="[route.url]">{{ route.displayName }}</a>
            </li>
            <li *ngIf="route.terminal" class="breadcrumb-item active" aria-current="page">{{ route.displayName }}</li>
          </ul>
        </ng-template>
      </div>
    </div>
</div>
</breadcrumb>