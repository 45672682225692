import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
// import { SwiperComponent } from 'ngx-useful-swiper';
import SwiperCore, { SwiperOptions, Autoplay } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

SwiperCore.use([Autoplay]);

@Component({
  selector: 'app-shop-item',
  templateUrl: './shop-item.component.html',
  styleUrls: ['./shop-item.component.css'],
})
export class ShopItemComponent implements OnInit, AfterViewInit {
  @ViewChild('usefulSwiper', { static: false }) usefulSwiper!: SwiperComponent;
  @Input() item: any;

  config: SwiperOptions = {
    pagination: false,
    speed: 400,
    spaceBetween: 30,
    autoplay: {
      delay: 1000,
    },
    scrollbar: { draggable: true },
  };

  constructor() {}

  ngAfterViewInit() {
    this.usefulSwiper.swiperRef.autoplay.stop();
  }

  ngOnInit(): void {}

  toggleSwipe(start: number) {
    start
      ? this.usefulSwiper.swiperRef.autoplay.start()
      : this.usefulSwiper.swiperRef.autoplay.stop();
    console.log(
      'to start ->',
      start,
      this.usefulSwiper.swiperRef.autoplay.running
    );
  }

  resetSlide() {
    console.log('resetttt');
    this.usefulSwiper.swiperRef.slideTo(0, 800);
  }
}
