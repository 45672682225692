import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  checkoutbanner = "assets/img/order-banner.jpg";
  
  paygiftcheck: boolean = true;
  promocodecheck: boolean = true;
  savecardcheck: boolean = false;
  addcardcheck: boolean = true;
  paygift() {
    this.paygiftcheck = !this.paygiftcheck;
  }
  promocode() {
    this.promocodecheck = !this.promocodecheck;
  }
  addcard() {
    this.addcardcheck = !this.addcardcheck;
  }
  savecard() {
    this.savecardcheck = !this.savecardcheck;
  }

}
