import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blogpost',
  templateUrl: './blogpost.component.html',
  styleUrls: ['./blogpost.component.css']
})
export class BlogpostComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  // blogposts
  blogposts = [
    { img: "assets/img/blog/blog1.jpg", tag1: "Water", tag2: "Health", title: "Save the American's Role in Fight Against Malnutrition Hailed", description: "Sharksucker sea toad candiru rocket danio tilefish stingray deepwater stingray Sacramento splittail canthigaster", postdate: "23 Jan' 20", postcomment: "501" },
    { img: "assets/img/blog/blog2.jpg", tag1: "Water", tag2: "Health", title: "Save the American's Role in Fight Against Malnutrition Hailed", description: "Sharksucker sea toad candiru rocket danio tilefish stingray deepwater stingray Sacramento splittail canthigaster", postdate: "23 Jan' 20", postcomment: "501" },
    { img: "assets/img/blog/blog3.jpg", tag1: "Water", tag2: "Health", title: "Save the American's Role in Fight Against Malnutrition Hailed", description: "Sharksucker sea toad candiru rocket danio tilefish stingray deepwater stingray Sacramento splittail canthigaster", postdate: "23 Jan' 20", postcomment: "501" },
    { img: "assets/img/blog/blog4.jpg", tag1: "Water", tag2: "Health", title: "Save the American's Role in Fight Against Malnutrition Hailed", description: "Sharksucker sea toad candiru rocket danio tilefish stingray deepwater stingray Sacramento splittail canthigaster", postdate: "23 Jan' 20", postcomment: "501" }
  ];

}
