import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Homepage1Component } from './components/pages/homepage1/homepage1.component';
import { Homepage2Component } from './components/pages/homepage2/homepage2.component';
import { Homepage3Component } from './components/pages/homepage3/homepage3.component';
import { Homepage4Component } from './components/pages/homepage4/homepage4.component';
import { Homepage5Component } from './components/pages/homepage5/homepage5.component';
import { AboutusComponent } from './components/pages/aboutus/aboutus.component';
import { ShopLeftComponent } from './components/pages/shop-left/shop-left.component';
import { ShopRightComponent } from './components/pages/shop-right/shop-right.component';
import { ShopDetailComponent } from './components/pages/shop-detail/shop-detail.component';
import { DonationArchiveComponent } from './components/pages/donation-archive/donation-archive.component';
import { DonationDetailComponent } from './components/pages/donation-detail/donation-detail.component';
import { WishlistComponent } from './components/pages/wishlist/wishlist.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { OrderComponent } from './components/pages/order/order.component';
import { Error404Component } from './components/pages/error404/error404.component';
import { LoginComponent } from './components/pages/login/login.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { BlogRightComponent } from './components/pages/blog-right/blog-right.component';
import { BlogLeftComponent } from './components/pages/blog-left/blog-left.component';
import { BlogDetailComponent } from './components/pages/blog-detail/blog-detail.component';
import { StoryArchiveComponent } from './components/pages/story-archive/story-archive.component';
import { StoryDetailComponent } from './components/pages/story-detail/story-detail.component';
import { ContactComponent } from './components/pages/contact/contact.component';

const routes: Routes = [

  { path: '', component: Homepage2Component, data: { breadcrumb: 'Homepage' } },
  { path: 'homepage2', component: Homepage2Component, data: { breadcrumb: 'Homepage 2' } },
  { path: 'homepage3', component: Homepage3Component, data: { breadcrumb: 'Homepage 3' }  },
  { path: 'homepage4', component: Homepage4Component, data: { breadcrumb: 'Homepage 4' }  },
  { path: 'homepage5', component: Homepage5Component, data: { breadcrumb: 'Homepage 5' }  },
  { path: 'about-us', component: AboutusComponent, data: { breadcrumb: 'About Us' } },
  { path: 'shop', component: ShopLeftComponent, data: { breadcrumb: 'Shop' }},
  { path: 'shop-right', component: ShopRightComponent, data: { breadcrumb: 'Shop Grid Right' } },
  { path: 'shop-detail', component: ShopDetailComponent, data: { breadcrumb: "Shop Details" } },
  { path: 'donation-archive', component: DonationArchiveComponent, data: { breadcrumb: "Donation Archive" }  },
  { path: 'donate-2', component: DonationDetailComponent, data: { breadcrumb: "Donation Details" }  },
  { path: 'donate', component: DonationArchiveComponent, data: { breadcrumb: "Donate" }  },
  { path: 'wishlist', component: WishlistComponent, data: { breadcrumb: "Wishlist" }  },
  { path: 'checkout', component: CheckoutComponent, data: { breadcrumb: "Checkout" }  },
  { path: 'order', component: OrderComponent, data: { breadcrumb: "Order Details" }  },
  { path: 'login', component: LoginComponent, data: { breadcrumb: 'Login' }  },
  { path: 'register', component: RegisterComponent, data: { breadcrumb: 'Register' }  },
  { path: 'blog-right', component: BlogRightComponent, data: { breadcrumb: "Blog Grid Right" }  },
  { path: 'blog-left', component: BlogLeftComponent, data: { breadcrumb: "Blog Grid Left" }  },
  { path: 'blog-detail', component: BlogDetailComponent, data: { breadcrumb: "Blog Details" }  },
  { path: 'story-archive', component: StoryArchiveComponent, data: { breadcrumb: "Story Archive" }  },
  { path: 'story-detail', component: StoryDetailComponent, data: { breadcrumb: "Story Details" }  },
  { path: 'contact', component: ContactComponent, data: { breadcrumb: "Contact Us" }  },
  { path: '**', component: Error404Component, data: { breadcrumb: '404' }  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
