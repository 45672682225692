<app-topbar></app-topbar>
<app-navbar></app-navbar>
<!-- breadcrumb -->
<app-breadcrumbs></app-breadcrumbs>
<!-- breadcrumb -->
<!--Shop-Details-->
<section class="section-padding  bg-theme-primary">
	<div class="container">
		<div class="row">
			<div class="col-lg-5">
				<div class="shop-detail-image">
					<div class="detail-slider">
						<swiper class="swiper-container gallery-top" [config]="gallerytopconfig">
							<div class="swiper-wrapper">
								<div class="swiper-slide" *ngFor="let shopgallery of shopgalleryposts">
									<a routerLink="/shop-detail" class="popup">
										<img src="{{shopgallery.img}}" class="img-fluid full-width" alt="slider">
									</a>
									<div class="shop-type-tag" *ngIf="shopgallery.tag">
										<a routerLink="/shop-detail"
											class="bg-custom-black type-tag">{{shopgallery.tag}}</a>
									</div>
								</div>
							</div>
							<!-- Add Arrows -->
							<!-- Add Arrows -->
							<div class="swiper-button-next swiper-button-white"></div>
							<div class="swiper-button-prev swiper-button-white"></div>
						</swiper>
						<swiper class="swiper-container gallery-thumbs" [config]="galleryThumbsconfig">
							<div class="swiper-wrapper">
								<div class="swiper-slide" *ngFor="let shopthumbs of shopthumbsposts">
									<img src="{{shopthumbs.img}}" class="img-fluid full-width" alt="slider">
								</div>
							</div>
							<!-- Add Arrows -->
							<span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
						</swiper>
					</div>
				</div>
			</div>
			<div class="col-lg-7">
				<div class="shop-detail-contents mb-md-40 mt-md-40">
					<div class="shop-detail-content-wrapper">
						<h6 class="text-custom-black">Handmade Golden Necklace Full Family Package</h6>
					</div>
					<div class="ratings d-flex mb-xl-20"> <span class="text-yellow"><i class="fas fa-star"></i></span>
						<span class="text-yellow"><i class="fas fa-star"></i></span>
						<span class="text-yellow"><i class="fas fa-star"></i></span>
						<span class="text-dark-white"><i class="fas fa-star"></i></span>
						<span class="text-dark-white"><i class="fas fa-star"></i></span>
						<div class="pro-review"> <span>1 Reviews</span>
						</div>
					</div>
					<div class="price">
						<h5 class="text-color-primary price-tag">$45 <span
								class="text-light-white fw-400 fs-14">$50</span></h5>
					</div>
					<div class="product-full-des mb-20">
						<p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
							incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
							exercitation ullamco laboris nisi ut aliquip</p>
					</div>
					<div class="availibity mt-20">
						<p class="text-custom-black fw-600">Avability: <span class="text-success ml-2">In Stock</span>
						</p>
					</div>
					<div class="quantity mb-xl-20">
						<p class="text-custom-black mb-0 mr-2 fw-600">Qty:</p>
						<div class="product-qty-input">
							<button class="minus-btn" type="button" name="button" (click)="decrement()">
								<i class="fas fa-minus"></i>
							</button>
							<input type="text" class="form-control form-control-qty text-center" name="name"
								value="{{counter}}">
							<button class="plus-btn" type="button" name="button" (click)="increment()">
								<i class="fas fa-plus"></i>
							</button>
						</div>
					</div>
					<div class="shop-bottom">
						<div class="shop-meta mt-20">
							<p class="text-custom-black mb-0 fw-600">Categories:</p>
							<ul class="list-inline ml-2">
								<li class="list-inline-item"><a routerLink="/shop-detail">Necklace</a>
								</li>
								<li class="list-inline-item"><a routerLink="/shop-detail">Diamond</a>
								</li>
								<li class="list-inline-item"><a routerLink="/shop-detail">Sale</a>
								</li>
							</ul>
						</div>
						<div class="shop-meta mt-20">
							<p class="text-custom-black mb-0 fw-600">Tags:</p>
							<ul class="list-inline ml-2">
								<li class="list-inline-item"><a routerLink="/shop-detail">Luxary</a>
								</li>
								<li class="list-inline-item"><a routerLink="/shop-detail">Diamond</a>
								</li>
								<li class="list-inline-item"><a routerLink="/shop-detail">New Arrivel</a>
								</li>
							</ul>
						</div>
						<div class="product-btn mt-20"> <a routerLink="/shop-detail"
								class="btn-solid with-line ml-2">Add to Cart </a>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12">
				<div class="shop-detail-description-sec">
					<div class="tabs">
						<ul class="nav nav-tabs" ngbNav #nav="ngbNav">
							<li class="nav-item" ngbNavItem>
								<a ngbNavLink class="nav-link" href="#">Description</a>
								<ng-template ngbNavContent>
									<p class=" mb-xl-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
										fringilla augue nec est tristique auctor. Ipsum metus feugiat sem, quis
										fermentum turpis eros eget velit. Donec ac tempus ante. Fusce ultricies massa
										massa. Fusce aliquam, purus eget sagittis vulputate, sapien libero hendrerit
										est, sed commodo augue nisi non neque.Cras neque metus, consequat et blandit et,
										luctus a nunc. Etiam gravida vehicula tellus, in imperdiet ligula euismod eget.
										Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac
										turpis egestas. Nam erat mi, rutrum at sollicitudin rhoncusp</p>
								</ng-template>
							</li>
							<li class="nav-item" ngbNavItem>
								<a ngbNavLink class="nav-link" href="#">Reviews (4)</a>
								<ng-template ngbNavContent>
									<!-- comments -->
									<div class="comment-box p-relative">
										<div id="comment-box">
											<ul class="comments">
												<li class="comment">
													<article>
														<div class="comment-avatar">
															<img src="assets/img/user/user-1.png" class="rounded-circle"
																alt="comment">
														</div>
														<div class="comment-content">
															<div class="comment-meta">
																<div class="comment-meta-header">
																	<h6 class="text-color-secondary fw-500 author mb-3">
																		Rosalina Pong</h6>
																	<div class="post-date">
																		<div class="date bg-custom-black">29 Jan <span
																				class="year bg-custom-red ">2022</span>
																		</div>
																	</div>
																</div>
																<div class="comment-meta-reply"> <a
																		routerLink="/shop-detail"
																		class="comment-reply-link btn-first red-btn text-custom-black">Reply</a>
																</div>
															</div>
															<div class="comment">
																<p>Lorem ipsum dolor sit amet, consectetur adipisicing
																	elit, sed do eiusmod tempor incididunt ut labore et
																	dolore magna aliqua. Ut enim ad minim veniam, quis
																	nostrud exercitation ullamco laboris nisi ut aliquip
																	ex ea commodo consequat.</p>
															</div>
														</div>
													</article>
												</li>
												<li class="comment">
													<article>
														<div class="comment-avatar ">
															<img src="assets/img/user/user-2.png" class="rounded-circle"
																alt="comment">
														</div>
														<div class="comment-content">
															<div class="comment-meta">
																<div class="comment-meta-header">
																	<h6 class="text-color-secondary fw-500 author mb-3">
																		Brian Wright</h6>
																	<div class="post-date">
																		<div class="date bg-custom-black">30 Jan <span
																				class="year bg-custom-red ">2022</span>
																		</div>
																	</div>
																</div>
																<div class="comment-meta-reply"> <a
																		routerLink="/shop-detail"
																		class="comment-reply-link btn-first red-btn text-custom-black">Reply</a>
																</div>
															</div>
															<div class="comment">
																<p>Lorem ipsum dolor sit amet, consectetur adipisicing
																	elit, sed do eiusmod tempor incididunt ut labore et
																	dolore magna aliqua. Ut enim ad minim veniam, quis
																	nostrud exercitation ullamco laboris nisi ut aliquip
																	ex ea commodo consequat.</p>
															</div>
														</div>
													</article>
													<ul class="children">
														<li class="comment">
															<article>
																<div class="comment-avatar">
																	<img src="assets/img/user/user-3.png"
																		class="rounded-circle" alt="comment">
																</div>
																<div class="comment-content ">
																	<div class="comment-meta">
																		<div class="comment-meta-header">
																			<h6
																				class="text-color-secondary fw-500 author mb-3">
																				Sarah Wright</h6>
																			<div class="post-date">
																				<div class="date bg-custom-black ">30
																					Jan <span
																						class="year bg-custom-red">2022</span>
																				</div>
																			</div>
																		</div>
																		<div class="comment-meta-reply"> <a
																				routerLink="/shop-detail"
																				class="comment-reply-link btn-first red-btn text-custom-black">Reply</a>
																		</div>
																	</div>
																	<div class="comment">
																		<p>Lorem ipsum dolor sit amet, consectetur
																			adipisicing elit, sed do eiusmod tempor
																			incididunt ut labore et dolore magna aliqua.
																			Ut enim ad minim veniam, quis nostrud
																			exercitation ullamco laboris nisi ut aliquip
																			ex ea commodo consequat.</p>
																	</div>
																</div>
															</article>
														</li>
													</ul>
												</li>
											</ul>
										</div>
									</div>
									<!-- comments -->
								</ng-template>
							</li>
						</ul>
						<div class="tab-content">
							<div [ngbNavOutlet]="nav"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- Shop-Details End -->
<!-- Shop-Details-Description -->
<app-shoprelated></app-shoprelated>
<!-- Shop-Details-Description END -->
<app-newsletter1></app-newsletter1>
<app-footer></app-footer>
