import { Component, OnInit } from '@angular/core';

@Component({ 
  selector: 'app-blog-sidebar',
  templateUrl: './blog-sidebar.component.html',
  styleUrls: ['./blog-sidebar.component.css']
})
export class BlogSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  // Popular post
  popposts = [
    { class: "pb-xl-20 mb-xl-20 u-line", img: "assets/img/blog/sblog1.jpg", title: "Brooklyn For A Better Place", date: "06 Jan 2022" },
    { class: "pb-xl-20 mb-xl-20 u-line", img: "assets/img/blog/sblog2.jpg", title: "15 Things To know Before Voting", date: "02 Feb 2022" },
    { class: "pb-xl-20 mb-xl-20 u-line", img: "assets/img/blog/sblog3.jpg", title: "Vote For The Best Party In Broolyn", date: "05 Jan 2022" },
    { img: "assets/img/blog/sblog4.jpg", title: "Best Political Party blog in Brooklyn", date: "21 Mar 2022" }
  ];
  // Tags
  tags = [
    { title: "Donation" },
    { title: "Education" },
    { title: "Handicraft" },
    { title: "Paintings" },
    { title: "Vote" },
    { title: "Trending" },
    { title: "Health" },
    { title: "Luxury" },
    { title: "Rings" },
    { title: "Pearl" },
    { title: "Luxury" },
    { title: "Bestseller" },
    { title: "Low Cost" }
  ];
  // Categories
  categories = [
    { name: "Children", count: "110" },
    { name: "Charity", count: "98" },
    { name: "Donation", count: "52" },
    { name: "Paintings", count: "26" },
    { name: "Antique", count: "55" },
    { name: "Handicraft", count: "48" },
    { name: "Ceramicart", count: "50" }
  ];

}
