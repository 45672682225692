import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import 'jquery-countdown';

@Component({
  selector: 'app-homepage4',
  templateUrl: './homepage4.component.html',
  styleUrls: ['./homepage4.component.css']
})
export class Homepage4Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
    var coundwn = $('.timer-wrap');
    $(coundwn).each(function () {
      (<any>$(coundwn)).countdown(coundwn.data('countdown'), function (event: { strftime: (arg0: string) => string; }) {
        $(coundwn).html(
          event.strftime('<div class="counter-box"> <span>%D</span></div><div class="counter-box"> <span>%H</span></div><div class="counter-box"> <span>%M</span></div><div class="counter-box"> <span>%S</span></div>')
        );
      });
    });
  }
  bannervideo = "assets/img/video-bg.mp4";
  featuredproimg = "assets/img/shop/featured.jpg";

  // Category Blocks
  categoryposts = [
    {img:"assets/img/categories/adv1.jpg",title:"Bracelets",description:"Lorem ipsum dolor sit amet conse ctetur adipisicing elit, sed do eiusmod",btntext:"Shop Now"},
    {parentclass:"white-block",img:"assets/img/categories/adv2.jpg",title:"Rings",description:"Lorem ipsum dolor sit amet conse ctetur adipisicing elit, sed do eiusmod",btntext:"Shop Now"},
    {img:"assets/img/categories/adv3.jpg",title:"Earrings",description:"Lorem ipsum dolor sit amet conse ctetur adipisicing elit, sed do eiusmod",btntext:"Shop Now"},
    {img:"assets/img/categories/adv4.jpg",title:"Bracelets",description:"Lorem ipsum dolor sit amet conse ctetur adipisicing elit, sed do eiusmod",btntext:"Shop Now"}
  ];
  // our bestseller Posts
  ourbestsellerposts = [
    { img: "assets/img/shop/product1.jpg", label1cls: "new", label1: "Accessories", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Campaign Shirt", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product2.jpg", label1cls: "new", label2cls: "discount", label1: "Accessories", label2: "25%", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Customized Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product3.jpg", label2cls: "discount", label3cls: "nonveg", label2: "25%", label3: "Wears", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Campaign Bag", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product4.jpg", label1cls: "new", label1: "Accessories", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Labour Sticker", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product2.jpg", label3cls: "nonveg", label3: "Wears", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Customized Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product4.jpg", label1cls: "new", label1: "Accessories", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Customized Pen", price: "90.00", discountprice: "250.00" }
  ];
  // Most rated Posts
  mostratedposts = [
    { img: "assets/img/shop/product3.jpg", label1cls: "new", label1: "Accessories", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Campaign Shirt", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product2.jpg", label1cls: "new", label2cls: "discount", label1: "Accessories", label2: "25%", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Customized Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product1.jpg", label2cls: "discount", label3cls: "nonveg", label2: "25%", label3: "Wears", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Campaign Bag", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product4.jpg", label1cls: "new", label1: "Accessories", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Labour Sticker", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product1.jpg", label3cls: "nonveg", label3: "Wears", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Customized Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product3.jpg", label1cls: "new", label1: "Accessories", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Customized Pen", price: "90.00", discountprice: "250.00" }
  ];
  // Most selling Posts
  mostsellingposts = [
    { img: "assets/img/shop/product1.jpg", label1cls: "new", label1: "Accessories", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Campaign Shirt", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product2.jpg", label1cls: "new", label2cls: "discount", label1: "Accessories", label2: "25%", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Customized Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product3.jpg", label2cls: "discount", label3cls: "nonveg", label2: "25%", label3: "Wears", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Campaign Bag", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product1.jpg", label1cls: "new", label1: "Accessories", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Labour Sticker", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product4.jpg", label3cls: "nonveg", label3: "Wears", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Customized Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product2.jpg", label1cls: "new", label1: "Accessories", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Customized Pen", price: "90.00", discountprice: "250.00" }
  ];
  // Fund Campaigns
  fundcampaingposts = [
    {img:"assets/img/shop/featured4.jpg",badge:"Finance",title:"Help for Children of the East",linktext:"Read More"},
    {img:"assets/img/shop/featured3.jpg",badge:"Finance",title:"Help for Children of the East",linktext:"Read More"}
  ];
  // Bestsellers Posts
  bestsellers = [
    { img: "assets/img/shop/product5.jpg", labelcls: "high", label: "Antique", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product6.jpg", labelcls: "uni", label: "Paintings", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product7.jpg", labelcls: "Handmade", label: "Handmade", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Metal Artwork", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product8.jpg", labelcls: "high", label: "Antique", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Voting Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product9.jpg", labelcls: "kids", label: "Handicrafts", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product10.jpg", labelcls: "Ceramicart", label: "Ceramicart", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product11.jpg", labelcls: "uni", label: "Paintings", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "USA Seal", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product5.jpg", labelcls: "kids", label: "Handicrafts", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Intricate Calendar", price: "90.00", discountprice: "250.00" }
  ];
  // Fund Campaigns
  donationposts = [
    {img:"assets/img/donation/barticle11.jpg",badge:"Finance",title:"Help for Children of the East",linktext:"Read More"},
    {img:"assets/img/donation/barticle12.jpg",badge:"Finance",title:"Help for Children of the East",linktext:"Read More"},
    {img:"assets/img/donation/barticle13.jpg",badge:"Finance",title:"Help for Children of the East",linktext:"Read More"},
    {img:"assets/img/donation/barticle14.jpg",badge:"Finance",title:"Help for Children of the East",linktext:"Read More"},
    {img:"assets/img/donation/barticle15.jpg",badge:"Finance",title:"Help for Children of the East",linktext:"Read More"},
    {img:"assets/img/donation/barticle16.jpg",badge:"Finance",title:"Help for Children of the East",linktext:"Read More"}
  ];
  // ourbestsellers post
  ourbestsellers = [
    { img: "assets/img/shop/product5.jpg", labelcls: "high", label: "Antique", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product7.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product8.jpg", labelcls: "Handmade", label: "Handmade", soldprice: "45,000", goalprice: "70,000", title: "Metal Artwork", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product6.jpg", labelcls: "high", label: "Antique", soldprice: "45,000", goalprice: "70,000", title: "Voting Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product9.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product10.jpg", labelcls: "Ceramicart", label: "Ceramicart", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product5.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Seal", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product11.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Intricate Calendar", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product9.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product7.jpg", labelcls: "Ceramicart", label: "Ceramicart", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product8.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Seal", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product7.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Intricate Calendar", price: "90.00", discountprice: "250.00" }
  ];
  // mostrated post
  mostratedpost = [
    { img: "assets/img/shop/product9.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product11.jpg", labelcls: "high", label: "Antique", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product1.jpg", labelcls: "Handmade", label: "Handmade", soldprice: "45,000", goalprice: "70,000", title: "Metal Artwork", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product11.jpg", labelcls: "high", label: "Antique", soldprice: "45,000", goalprice: "70,000", title: "Voting Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product10.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product9.jpg", labelcls: "Ceramicart", label: "Ceramicart", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product8.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Seal", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product7.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Intricate Calendar", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product6.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product6.jpg", labelcls: "Ceramicart", label: "Ceramicart", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product5.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Seal", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product9.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Intricate Calendar", price: "90.00", discountprice: "250.00" }
  ];
  // mostselling post
  mostsellingpost = [
    { img: "assets/img/shop/product11.jpg", labelcls: "high", label: "Antique", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product10.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product8.jpg", labelcls: "Handmade", label: "Handmade", soldprice: "45,000", goalprice: "70,000", title: "Metal Artwork", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product11.jpg", labelcls: "high", label: "Antique", soldprice: "45,000", goalprice: "70,000", title: "Voting Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product10.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product9.jpg", labelcls: "Ceramicart", label: "Ceramicart", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product8.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Seal", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product7.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Intricate Calendar", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product6.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product5.jpg", labelcls: "Ceramicart", label: "Ceramicart", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product9.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Seal", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product11.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Intricate Calendar", price: "90.00", discountprice: "250.00" }
  ];

}
