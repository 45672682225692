import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  aboutimg = "assets/img/about1.jpg";
  aboutsign = "assets/img/about1.png";

  // quoteposts
  quoteposts = [
    {text:"Supporting the LiveWell Lottery for 18 Years",author:"Jhone Deo"},
    {text:"Supporting the LiveWell Lottery for 18 Years",author:"Jhone Deo"},
    {text:"Supporting the LiveWell Lottery for 18 Years",author:"Jhone Deo"}
  ];
  processposts = [
    {number:"01",icon:"assets/img/how1.png",title:"Search",description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut.",arrowclass:"arrow-1"},
    {number:"02",icon:"assets/img/how2.png",title:"Select",description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut.",arrowclass:"arrow-2"},
    {number:"03",icon:"assets/img/how3.png",title:"Order",description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut.",arrowclass:"arrow-1"},
    {number:"04",icon:"assets/img/how4.png",title:"Enjoy",description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut."}
  ];

}
