import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-shop-detail',
  templateUrl: './shop-detail.component.html',
  styleUrls: ['./shop-detail.component.css']
})
export class ShopDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  // Increment decrement
  public counter: number = 1;
  increment() {
    this.counter += 1;
  }

  decrement() {
    this.counter -= 1;
  }

  // Gallery config
  galleryThumbsconfig: SwiperOptions = {
    spaceBetween: 15,
    slidesPerView: 5,
    freeMode: true,
    // watchSlidesVisibility: true,
    // watchSlidesProgress: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
  };
  gallerytopconfig: SwiperOptions = {
    spaceBetween: 15,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    loop: true
  };
  // Gallery
  shopgalleryposts = [
    { img: "assets/img/shop/maindetail.jpg", tag: "Sale" },
    { img: "assets/img/shop/maindetail.jpg", tag: "Sale" },
    { img: "assets/img/shop/maindetail.jpg", tag: "Sale" },
    { img: "assets/img/shop/maindetail.jpg", tag: "Sale" },
    { img: "assets/img/shop/maindetail.jpg", tag: "Sale" },
    { img: "assets/img/shop/maindetail.jpg", tag: "Sale" }
  ];
  shopthumbsposts = [
    { img: "assets/img/shop/shop-d96x96.jpg" },
    { img: "assets/img/shop/shop-d96x96.jpg" },
    { img: "assets/img/shop/shop-d96x96.jpg" },
    { img: "assets/img/shop/shop-d96x96.jpg" },
    { img: "assets/img/shop/shop-d96x96.jpg" },
    { img: "assets/img/shop/shop-d96x96.jpg" }
  ];


}
