import { Component, AfterViewInit, ElementRef, ViewChild} from '@angular/core';
import * as $ from 'jquery';
import 'magnific-popup';
import 'jquery-countdown';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-homepage3',
  templateUrl: './homepage3.component.html',
  styleUrls: ['./homepage3.component.css']
})
export class Homepage3Component implements AfterViewInit {

  constructor() { }

  @ViewChild('video') videoElement: ElementRef | any;
  ngAfterViewInit(): void {
    // video popup
    ($(this.videoElement.nativeElement) as any).magnificPopup({ type: 'iframe' });

    // Counter
    var coundwn = $('.timer-white');
    $(coundwn).each(function () {
      (<any>$(coundwn)).countdown(coundwn.data('countdown'), function (event: { strftime: (arg0: string) => string; }) {
        $(coundwn).html(
          event.strftime('<div class="time-box"> <span>%D</span>Days</div><div class="time-box"> <span>%H</span>Hr</div><div class="time-box"> <span>%M</span>Mins</div><div class="time-box"> <span>%S</span>Sec</div>')
        );
      });
    });
    // Counter
    var coundwntwo = $('.timer-wrap');
    $(coundwntwo).each(function () {
      (<any>$(coundwntwo)).countdown(coundwntwo.data('countdown'), function (event: { strftime: (arg0: string) => string; }) {
        $(coundwntwo).html(
          event.strftime('<div class="counter-box"> <span>%D</span>Days</div><div class="counter-box"> <span>%H</span>Hr</div><div class="counter-box"> <span>%M</span>Mins</div><div class="counter-box"> <span>%S</span>Sec</div>')
        );
      });
    });
  }
  bannerimg = "assets/img/banner/banner1.jpg";
  featuredproimg = "assets/img/shop/featured.jpg";
  featuredimg = "assets/img/shop/featured2.jpg";
  // Categories posts
  categoriesposts = [
    {img:"assets/img/categories/category1.png", caption:"Martha, NY"},
    {img:"assets/img/categories/category2.png", caption:"Michle, CA"},
    {img:"assets/img/categories/category3.png", caption:"Adem, LA"},
    {img:"assets/img/categories/category4.png", caption:"Jimmy, CA"},
    {img:"assets/img/categories/category5.png", caption:"Nico, FL"},
    {img:"assets/img/categories/category6.png", caption:"Richa, NY"},
    {img:"assets/img/categories/category7.png", caption:"Jordan, NY"},
    {img:"assets/img/categories/category8.png", caption:"Hemlet, NJ"},
    {img:"assets/img/categories/category9.png", caption:"Ricky, LA"},
    {img:"assets/img/categories/category8.png", caption:"Casendra, LA"},
    {img:"assets/img/categories/category9.png", caption:"Rob, CA"},
    {img:"assets/img/categories/category8.png", caption:"Mily, TX"},
    {img:"assets/img/categories/category9.png", caption:"Rob, NY"}
  ];
  // categoryconfig
  categoryconfig: SwiperOptions = {
    slidesPerView: 2,
    spaceBetween: 15,
    loop: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      576: {
        slidesPerView: 3,
        spaceBetween: 5,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 40,
      },
      992: {
        slidesPerView: 6,
        spaceBetween: 40,
      },
      1200: {
        slidesPerView: 8,
        spaceBetween: 5,
      },
    }
  };
  // ourbestsellers post
  ourbestsellers = [
    { img: "assets/img/shop/product5.jpg", labelcls: "high", label: "Antique", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product7.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product8.jpg", labelcls: "Handmade", label: "Handmade", soldprice: "45,000", goalprice: "70,000", title: "Metal Artwork", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product6.jpg", labelcls: "high", label: "Antique", soldprice: "45,000", goalprice: "70,000", title: "Voting Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product9.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product10.jpg", labelcls: "Ceramicart", label: "Ceramicart", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product5.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Seal", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product11.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Intricate Calendar", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product9.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product7.jpg", labelcls: "Ceramicart", label: "Ceramicart", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product8.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Seal", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product7.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Intricate Calendar", price: "90.00", discountprice: "250.00" }
  ];
  // mostrated post
  mostratedposts = [
    { img: "assets/img/shop/product9.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product11.jpg", labelcls: "high", label: "Antique", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product1.jpg", labelcls: "Handmade", label: "Handmade", soldprice: "45,000", goalprice: "70,000", title: "Metal Artwork", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product11.jpg", labelcls: "high", label: "Antique", soldprice: "45,000", goalprice: "70,000", title: "Voting Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product10.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product9.jpg", labelcls: "Ceramicart", label: "Ceramicart", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product8.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Seal", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product7.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Intricate Calendar", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product6.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product6.jpg", labelcls: "Ceramicart", label: "Ceramicart", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product5.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Seal", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product9.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Intricate Calendar", price: "90.00", discountprice: "250.00" }
  ];
  // mostselling post
  mostsellingpost = [
    { img: "assets/img/shop/product11.jpg", labelcls: "high", label: "Antique", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product10.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product8.jpg", labelcls: "Handmade", label: "Handmade", soldprice: "45,000", goalprice: "70,000", title: "Metal Artwork", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product11.jpg", labelcls: "high", label: "Antique", soldprice: "45,000", goalprice: "70,000", title: "Voting Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product10.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product9.jpg", labelcls: "Ceramicart", label: "Ceramicart", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product8.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Seal", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product7.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Intricate Calendar", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product6.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product5.jpg", labelcls: "Ceramicart", label: "Ceramicart", soldprice: "45,000", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product9.jpg", labelcls: "uni", label: "Paintings", soldprice: "45,000", goalprice: "70,000", title: "USA Seal", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product11.jpg", labelcls: "kids", label: "Handicrafts", soldprice: "45,000", goalprice: "70,000", title: "Intricate Calendar", price: "90.00", discountprice: "250.00" }
  ];
  // hot deals
  hotdealposts = [
    {img:"assets/img/help.jpg",badge:"Enviroment",title:"More than One Life Changed",linktext:"Read More"},
    {img:"assets/img/help1.jpg",badge:"Education",title:"Help for Children of the East",linktext:"Read More"},
    {img:"assets/img/help2.jpg",badge:"Food & Meals",title:"More than One Life Changed",linktext:"Read More"}
  ];
  // Bestsellers Posts
  bestsellers = [
    { img: "assets/img/shop/product5.jpg", labelcls: "high", label: "Antique", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product6.jpg", labelcls: "uni", label: "Paintings", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product7.jpg", labelcls: "Handmade", label: "Handmade", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Metal Artwork", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product8.jpg", labelcls: "high", label: "Antique", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Voting Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product9.jpg", labelcls: "kids", label: "Handicrafts", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product10.jpg", labelcls: "Ceramicart", label: "Ceramicart", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product11.jpg", labelcls: "uni", label: "Paintings", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "USA Seal", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product5.jpg", labelcls: "kids", label: "Handicrafts", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Intricate Calendar", price: "90.00", discountprice: "250.00" }
  ];
  // Campaigns post
  campaignposts = [
    { title: "Help our Veterans come back", description: "Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem.", img: "assets/img/donation/article1.jpg", category: "Education", raiseprice: "4585.00", goalprice: "4585.00", linktext: "Donate Now" },
    { title: "Help our Veterans come back", description: "Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem.", img: "assets/img/donation/article3.jpg", category: "Health", raiseprice: "4585.00", goalprice: "4585.00", linktext: "Donate Now" },
    { title: "Help our Veterans come back", description: "Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem.", img: "assets/img/donation/article2.jpg", category: "Hygine", raiseprice: "4585.00", goalprice: "4585.00", linktext: "Donate Now" },
    { title: "Help our Veterans come back", description: "Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem.", img: "assets/img/donation/article5.jpg", category: "Hygine", raiseprice: "4585.00", goalprice: "4585.00", linktext: "Donate Now" }
  ];
  // blogposts
  blogposts = [
    { img: "assets/img/blog/blog4.jpg", tag1: "Water", tag2: "Health", title: "Save the American's Role in Fight Against Malnutrition Hailed", description: "Sharksucker sea toad candiru rocket danio tilefish stingray deepwater stingray Sacramento splittail canthigaster", postdate: "23 Jan' 20", postcomment: "501" },
    { img: "assets/img/blog/blog3.jpg", tag1: "Water", tag2: "Health", title: "Save the American's Role in Fight Against Malnutrition Hailed", description: "Sharksucker sea toad candiru rocket danio tilefish stingray deepwater stingray Sacramento splittail canthigaster", postdate: "23 Jan' 20", postcomment: "501" },
    { img: "assets/img/blog/blog2.jpg", tag1: "Water", tag2: "Health", title: "Save the American's Role in Fight Against Malnutrition Hailed", description: "Sharksucker sea toad candiru rocket danio tilefish stingray deepwater stingray Sacramento splittail canthigaster", postdate: "23 Jan' 20", postcomment: "501" }
  ];

}
