import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  // Logo
  logo = 'assets/img/logo-new-white.png';
  constructor() { }
  // instagramconfig
  instagramconfig: SwiperOptions = {
    slidesPerView: 2,
    loop: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    navigation: false,
    breakpoints: {
      480: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 6,
      },
      1500: {
        slidesPerView: 8,
      },
    }

  };
  instagramposts = [
    { img: "assets/img/insta/insta1.jpg" },
    { img: "assets/img/insta/insta2.jpg" },
    { img: "assets/img/insta/insta3.jpg" },
    { img: "assets/img/insta/insta4.jpg" },
    { img: "assets/img/insta/insta5.jpg" },
    { img: "assets/img/insta/insta6.jpg" },
    { img: "assets/img/insta/insta7.jpg" },
    { img: "assets/img/insta/insta6.jpg" },
  ];

  ngOnInit(): void {
  }

}
