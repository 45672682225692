import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-donation-archive',
  templateUrl: './donation-archive.component.html',
  styleUrls: ['./donation-archive.component.css']
})
export class DonationArchiveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  // Donation post
  bannerImg = 'assets/img/Peter-Obi-For-President.jpg';
  blockposts = [
    {img:"assets/img/donation/article9.jpg",category:"Education",title:"Help our Veterans come back",description:"Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem.",raised:"4585.00",goal:"4585.00",btntext:"Donate Now"},
    {img:"assets/img/donation/article8.jpg",category:"Education",title:"Help our Veterans come back",description:"Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem.",raised:"4585.00",goal:"4585.00",btntext:"Donate Now"},
    {img:"assets/img/donation/article7.jpg",category:"Education",title:"Help our Veterans come back",description:"Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem.",raised:"4585.00",goal:"4585.00",btntext:"Donate Now"},
    {img:"assets/img/donation/article6.jpg",category:"Education",title:"Help our Veterans come back",description:"Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem.",raised:"4585.00",goal:"4585.00",btntext:"Donate Now"},
    {img:"assets/img/donation/article5.jpg",category:"Education",title:"Help our Veterans come back",description:"Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem.",raised:"4585.00",goal:"4585.00",btntext:"Donate Now"},
    {img:"assets/img/donation/article4.jpg",category:"Education",title:"Help our Veterans come back",description:"Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem.",raised:"4585.00",goal:"4585.00",btntext:"Donate Now"},
    {img:"assets/img/donation/article3.jpg",category:"Education",title:"Help our Veterans come back",description:"Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem.",raised:"4585.00",goal:"4585.00",btntext:"Donate Now"},
    {img:"assets/img/donation/article2.jpg",category:"Education",title:"Help our Veterans come back",description:"Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem.",raised:"4585.00",goal:"4585.00",btntext:"Donate Now"},
    {img:"assets/img/donation/article1.jpg",category:"Education",title:"Help our Veterans come back",description:"Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem.",raised:"4585.00",goal:"4585.00",btntext:"Donate Now"}
  ];

}
