<app-topbar></app-topbar>
<app-navbar></app-navbar>
<!-- breadcrumb -->
<app-breadcrumbs></app-breadcrumbs>
<!-- breadcrumb -->
<!-- Blog --> 
<section class="our-articles bg-light-theme section-padding ">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-xl-12">
				<div class=" full-width">
					<div class="row">
						<div class="col-xl-3 col-lg-6 col-md-6" *ngFor="let storybox of storyboxes">
							<div class="sa-causes-single sa-causes-single-2">
								<div class="entry-thumb">
									<img src="{{storybox.img}}" alt="img" class="img-fluid full-width">
									<div class="dontaion-category story-meta">
										<p><strong>{{storybox.authorname}}</strong></p>
										<p>Goal: <strong>${{storybox.goalprice}}</strong></p>
									</div>
								</div>
								<div class="causes-details-wrap">
									<div class="causes-details">
										<h5><a routerLink="/story-detail">{{storybox.title}}</a></h5>
										<p>{{storybox.text}}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Pagination -->
				<app-pagination></app-pagination>
			</div>
		</div>
	</div>
</section>
<app-newsletter2></app-newsletter2>
<app-footer></app-footer>