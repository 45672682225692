<section class="section-padding shop-description">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="section-header-left title">
					<h3 class="text-light-black header-title">Related Items</h3>
					<span class="fs-14">
						<a routerLink="/order">See all</a>
					</span>
				</div>
			</div>
			<div class="col-xl-3 col-lg-3 col-md-6 col-sm-6" *ngFor="let relatedshop of relatedshopposts">
				<div class="product-box mb-md-20">
					<div class="product-img">
						<a routerLink="/shop-detail">
							<img src="{{relatedshop.img}}" class="img-fluid full-width" alt="product-img">
						</a>
						<div class="product-badge" *ngIf="relatedshop.tagclass">
							<div class="product-label {{relatedshop.tagclass}}"> <span>{{relatedshop.tagname}}</span>
							</div>
						</div>
                        <div class="button-group">
                            <a routerLink="/wishlist" placement="left" title=""
                                ngbTooltip="Add to wishlist" tabindex="-1">
                                <i class="pe-7s-like"></i>
                            </a>
                            <a routerLink="/" placement="left" title="" ngbTooltip="Quick View">
                                <span>
                                    <i class="pe-7s-search"></i>
                                </span>
                            </a>
                        </div>
						<div class="cart-hover">
							<a routerLink="/shop-detail" class="btn-cart  fw-600" tabindex="-1">{{relatedshop.carttext}}</a>
						</div>
					</div>
					<div class="product-caption text-center">
						<div class="product-status">
							<ul class="product-raised">
								<li><strong>Sold:</strong> {{relatedshop.soldprice}}</li>
								<li><strong>Goal:</strong><span class="text-highlight"> {{relatedshop.goalprice}}</span></li>
							</ul>
							<div class="progress">
								<div class="progress-bar progress-bar-color" style="width: 75%;"></div>
							</div>
						</div>
						<h6 class="product-title fw-500 mt-10"><a routerLink="/shop-detail"
								class="text-color-secondary">{{relatedshop.title}}</a></h6>
						<div class="product-money mt-10"> <span class="text-color-primary fw-600 fs-16">${{relatedshop.price}}</span>
							<span class="text-price">${{relatedshop.discountprice}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
