<app-topbar></app-topbar>
<app-navbar></app-navbar>

<!-- slider -->
<swiper [config]="aboutconfig" class="about-us-slider swiper-container p-relative slider-banner-1">
	<div class="swiper-wrapper">
		<div class="swiper-slide slide-item" *ngFor="let aboutsliderpost of aboutsliderposts">
			<img src="{{aboutsliderpost.img}}" class="img-fluid full-width" alt="Banner">
			<div class="transform-center z-index-3">
				<div class="container-fluid custom-container">
					<div class="row justify-content-center">
						<div class="col-lg-12 align-self-center">
							<div class="right-side-content far-right">
								<h5 class="text-white">{{aboutsliderpost.subtitle}}</h5>
								<h1 class="text-white fw-600">{{aboutsliderpost.title}} <span
										class="text-custom-pink">{{aboutsliderpost.titlespan}}</span>
									{{aboutsliderpost.titlespanafter}}</h1>
								<p class="text-white fw-400">{{aboutsliderpost.description}}</p>
								<a routerLink="/about-us"
									class="btn-solid with-line btn-big mt-20 mr-1"><span>{{aboutsliderpost.btn1}} <i
											class="fas fa-caret-right"></i></span></a>
								<a routerLink="/shop" class="border-butn mt-20 ml-1"><span>{{aboutsliderpost.btn2}}
									</span></a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="overlay overlay-bg-dark overlay-bg-img"></div>
		</div>
	</div>
	<!-- Add Arrows -->
	<div class="swiper-button-next about-button-next"></div>
	<div class="swiper-button-prev about-button-prev"></div>
</swiper>

<!-- slider -->
<section class="service-type">
	<div class="row">
		<div class="col-lg-3 col-md-6 {{servicetypepost.parentclass}}" *ngFor="let servicetypepost of servicetypeposts">
			<div class="service-box">
				<div class="service-box-wrapper">
					<div class="service-icon-box">
						<img src="{{servicetypepost.img}}" alt="" class="img-fluid">
					</div>
					<div class="service-text-box">
						<p>{{servicetypepost.title}}</p>
						<h6>{{servicetypepost.subtitle}}</h6>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- Browse by category -->
<section class="browse-cat bg-theme-primary section-padding">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-12">
				<div class="section-header-left title">
					<h3 class="text-light-black header-title">Our Candidates List </h3>
					<span class="fs-16 align-self-center"><a routerLink="/">See All</a></span>
				</div>
			</div>
			<div class="col-12">
				<swiper [config]="categoryconfig" class="category-slider swiper-container">
					<div class="swiper-wrapper">
						<div class="swiper-slide" *ngFor="let categorypost of categoryposts">
							<a routerLink="/story-archive" class="categories">
								<div class="icon text-custom-white bg-light-green ">
									<img src="{{categorypost.img}}" class="rounded-circle"
										alt="categories">
								</div> <span class="text-light-black cat-name">{{categorypost.title}}</span>
							</a>
						</div>
					</div>
				</swiper>
				<!-- Add Arrows -->
				<div class="swiper-button-next"></div>
				<div class="swiper-button-prev"></div>
			</div>
		</div>
	</div>
</section>
<!-- Browse by category -->

<!-- sell section -->
<section class="recent-order section-padding">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-12">
				<div class="section-header-left title">
					<h3 class="text-light-black header-title">Help Us Fund Our Campaign</h3>
					<span class="fs-16"><a routerLink="/order">See All Bestseller</a></span>
				</div>
			</div>
			<div class="col-xl-2 col-lg-4 col-md-6 col-sm-6" *ngFor="let shoppost of shopposts">
				<div class="product-box mb-md-20">
					<div class="product-img">
						<a routerLink="/shop-detail">
							<img src="{{shoppost.img}}" class="img-fluid full-width" alt="product-img">
						</a>
						<div class="product-badge">
							<div class="product-label {{shoppost.label1cls}}" *ngIf="shoppost.label1cls"> <span>{{shoppost.label1}}</span>
							</div>
							<div class="product-label {{shoppost.label3cls}}" *ngIf="shoppost.label3cls"> <span>{{shoppost.label3}}</span>
							</div>
							<div class="product-label {{shoppost.label2cls}}" *ngIf="shoppost.label2cls"> <span>{{shoppost.label2}}</span>
							</div>
						</div>
						<div class="button-group">
							<a routerLink="/wishlist" placement="left" title="" ngbTooltip="Add to wishlist" tabindex="-1">
								<i class="pe-7s-like"></i>
							</a>
							<a routerLink="/" placement="left" title="" ngbTooltip="Quick View">
								<span >
									<i class="pe-7s-search"></i>
								</span>
							</a>
						</div>
						<div class="cart-hover">
							<a routerLink="/shop-detail" class="btn-cart fw-600" tabindex="-1">{{shoppost.carttext}}</a>
						</div>
					</div>
					<div class="product-caption text-center">
						<div class="product-status">
							<ul class="product-raised">
								<li><strong>{{shoppost.soldtitle}}:</strong> {{shoppost.soldprice}}</li>
								<li><strong>{{shoppost.goaltitle}}:</strong><span class="text-highlight"> {{shoppost.goalprice}}</span></li>
							</ul>
							<div class="progress">
								<div class="progress-bar progress-bar-color" style="width: 75%;"></div>
							</div>
						</div>
						<h6 class="product-title fw-500 mt-10"><a routerLink="/shop-detail"
								class="text-color-secondary">{{shoppost.title}}</a></h6>
						<div class="product-money mt-10"> <span class="text-color-primary fw-600 fs-16">${{shoppost.price}}</span>
							<span class="text-price">${{shoppost.discountprice}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- sell section end -->

<section class="masonary-gallery-sec">
	<div class="container-fluid no-padding">
		<div class="row magnific-gallery">
			<div class="col-lg-6">
				<div class="full-height masonary-box p-relative">
					<div class="masonary-text transform-center">
						<div class="masonary-text-wrapper">
							<div class="icon-box">
								<img src="assets/img/gallery/thisisimage6.jpg" class="image-fit" alt="img">
							</div>
							<div class="text-box">
								<h3 class="text-custom-white">
									Stay up-todate, Follow us on our Instagram & Twitter page for daily updates
								</h3>
								<a routerLink="/" class="text-custom-white fs-14"><i class="fab fa-instagram mr-2"></i>
									@Political</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="video-box full-height">
					<div class="video_wrapper video_wrapper_full js-videoWrapper">
						<iframe class="videoIframe js-videoIframe" src="https://www.youtube.com/embed/AdZrEIo6UYU"
							data-src="https://www.youtube.com/embed/AdZrEIo6UYU?autoplay=1" allow="autoplay"></iframe>
						<div class="videoPoster js-videoPoster">
							<img src="assets/img/gallery/thisisimage1.jpg" class="image-fit" alt="#">
							<div class="video-inner video-btn-wrapper"> <a href="https://www.youtube.com/watch?v=AdZrEIo6UYU" #video class="video-popup"><i
										class="pe-7s-play" ></i></a></div>
						</div>
					</div>
				</div>
			</div>
			<div class="row pt-0">
				<div class="{{ gallerypost.parentclass }}"  *ngFor="let gallerypost of galleryposts">
					<div class="masonary-item p-relative">
						<a href="{{ gallerypost.img }}" #img class="image-popup">
							<img src="{{ gallerypost.img }}" class="image-fit" alt="img">
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- Hot Deals -->
<section class="ex-collection section-padding bg-theme-primary">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-12">
				<div class="section-header-left">
					<h3 class="text-light-black header-title title"> Our Promise To The Community</h3>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="{{ communitypost.colclass }}" *ngFor="let communitypost of communityposts">
				<div class="story-wrapper">
					<img src="{{ communitypost.img }}" alt="" class="full-width img-fluid mx-auto d-block">
					<div class="story-box-content story-content-wrapper">
						<span class="story-badge bg-custom-primary text-color-secondary">{{ communitypost.badge }}</span>
						<h5><a routerLink="/">{{ communitypost.title }}</a> </h5>
						<a routerLink="/donation-detail" class="btn btn-text btn-text-white">{{ communitypost.linktext }}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--Hot deals End-->

<!--shopping section-->
<section class="section-padding our-product">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-12">
				<div class="section-header-left">
					<h3 class="text-light-black header-title title">Our Best Sellers</h3>
				</div>
			</div>
			<div class="col-xl-3 col-lg-4 col-md-4">
				<div class="large-product-box  p-relative">
					<div class="featured-product-box box-shadow">
						<div class="featured-pro-title">
							<h4 class="fs-22"><strong class="text-color-primary"> Vote</strong> now don't postpone</h4>
						</div>
						<div class="featured-pro-content">
							<div class="featured-pro-text">
								<h5><a routerLink="/">Vote for a better community.</a></h5>
								<p>Lorem ipsum dolor sit amet, ctetur adipiscing elit, sed do eiusmod</p>
								<p class="price">$244</p>
							</div>
						</div>
						<div class="featured-pro-img">
							<img src="{{ featuredproimg }}" alt="pro-img" class="img-fluid mx-auto d-block">
						</div>
						<div class="featured-pro-timer">
							<div class="countdown-box sigma_countdown-timer" data-countdown="2022/12/06"> </div>
						</div>
						<div class="featured-pro-bottom">
							<ul>
								<li>Votes: <strong>150000 </strong></li>
								<li>Goal: <strong>200000</strong> </li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-9 col-lg-8 col-md-8">
				<div class="row">
					<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6" *ngFor="let bestseller of bestsellers">
						<div class="product-box mb-md-20">
							<div class="product-img">
								<a routerLink="/shop-detail">
									<img src="{{ bestseller.img }}" class="img-fluid full-width"
										alt="product-img">
								</a>
								<div class="product-badge">
									<div class="product-label {{ bestseller.labelcls }}"> <span>{{ bestseller.label }}</span>
									</div>
								</div>
								<div class="button-group">
									<a routerLink="/wishlist" placement="left" title="" ngbTooltip="Add to wishlist" tabindex="-1">
										<i class="pe-7s-like"></i>
									</a>
									<a routerLink="/" placement="left" title="" ngbTooltip="Quick View">
										<span >
											<i class="pe-7s-search"></i>
										</span>
									</a>
								</div>
								<div class="cart-hover">
									<a routerLink="/shop-detail" class="btn-cart  fw-600" tabindex="-1">{{ bestseller.carttext }}</a>
								</div>
							</div>
							<div class="product-caption text-center">
								<div class="product-status">
									<ul class="product-raised">
										<li><strong>{{ bestseller.soldtitle }}:</strong> {{ bestseller.soldprice }}</li>
										<li><strong>{{ bestseller.goaltitle }}:</strong><span class="text-highlight"> {{ bestseller.soldprice }}</span></li>
									</ul>
									<div class="progress">
										<div class="progress-bar progress-bar-color" style="width: 75%;"></div>
									</div>
								</div>
								<h6 class="product-title fw-500 mt-10"><a routerLink="/shop-detail"
										class="text-color-secondary">{{ bestseller.title }}</a></h6>
								<div class="product-money mt-10"> <span
										class="text-color-primary fw-600 fs-16">${{ bestseller.price }}</span>
									<span class="text-price">${{ bestseller.discountprice }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--shopping section end-->

<!-- advertisement banner-->
<app-advertisement></app-advertisement>
<!-- advertisement banner-->

<!--Special offer-->
<section class="section-padding ex-collection bg-theme-primary">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-12">
				<div class="section-header-left">
					<h3 class="text-light-black header-title title">Our Donation Campaigns</h3>
				</div>
			</div>
			<div class="col-xl-4 col-lg-6 col-md-6" *ngFor="let campaignpost of campaignposts">
				<div class="sa-causes-single sa-causes-single-2">
					<div class="causes-details-wrap">
						<div class="causes-details">
							<h5><a routerLink="/donation-detail">{{ campaignpost.title }}</a></h5>
							<p>{{ campaignpost.description }}</p>
							<div class="entry-thumb mtmb-spacing">
								<img src="{{ campaignpost.img }}" alt="img" class="img-fluid full-width">
								<div class="dontaion-category"><a routerLink="/">{{ campaignpost.category }}</a></div>
							</div>
							<div class="cause-progress">
								<div class="progress-bar" role="progressbar" aria-valuenow="17" aria-valuemin="0"
									aria-valuemax="100" style="width: 17%;">
									<span>17%</span>
								</div>
							</div>
							<div class="causes-amount">
								<div class="left">
									<p>Raised</p>
									<span>${{ campaignpost.raiseprice }}</span>
								</div>
								<div class="right">
									<p>Goal</p>
									<span>${{ campaignpost.goalprice }}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="btn-area text-center">
						<a class="btn-donation text-btn" routerLink="/donation-detail">{{ campaignpost.linktext }}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<!--shopping section-->
<section class="section-padding our-product">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-12">
				<div class="section-header-left">
					<h3 class="text-light-black header-title title"> Help us fund our campaigns</h3>
				</div>
			</div>
			<div class="col-xl-9 col-lg-8 col-md-8">
				<div class="row">
					<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6" *ngFor="let bestseller of bestsellers">
						<div class="product-box mb-md-20">
							<div class="product-img">
								<a routerLink="/shop-detail">
									<img src="{{ bestseller.img }}" class="img-fluid full-width"
										alt="product-img">
								</a>
								<div class="product-badge">
									<div class="product-label {{ bestseller.labelcls }}"> <span>{{ bestseller.label }}</span>
									</div>
								</div>
								<div class="button-group">
									<a routerLink="/wishlist" placement="left" title="" ngbTooltip="Add to wishlist" tabindex="-1">
										<i class="pe-7s-like"></i>
									</a>
									<a routerLink="/" placement="left" title="" ngbTooltip="Quick View">
										<span >
											<i class="pe-7s-search"></i>
										</span>
									</a>
								</div>
								<div class="cart-hover">
									<a routerLink="/shop-detail" class="btn-cart  fw-600" tabindex="-1">{{ bestseller.carttext }}</a>
								</div>
							</div>
							<div class="product-caption text-center">
								<div class="product-status">
									<ul class="product-raised">
										<li><strong>{{ bestseller.soldtitle }}:</strong> {{ bestseller.soldprice }}</li>
										<li><strong>{{ bestseller.goaltitle }}:</strong><span class="text-highlight"> {{ bestseller.soldprice }}</span></li>
									</ul>
									<div class="progress">
										<div class="progress-bar progress-bar-color" style="width: 75%;"></div>
									</div>
								</div>
								<h6 class="product-title fw-500 mt-10"><a routerLink="/shop-detail"
										class="text-color-secondary">{{ bestseller.title }}</a></h6>
								<div class="product-money mt-10"> <span
										class="text-color-primary fw-600 fs-16">${{ bestseller.price }}</span>
									<span class="text-price">${{ bestseller.discountprice }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-3 col-lg-4 col-md-4">
				<div class="verticle-newsletter bg-custom-white main-box">
					<div class="verticle-newsletter-inner">
						<h6 class="text-light-black">Subscribe To Newsletter</h6>
						<p class="text-light-white mb-xl-20">Lorem Ipsum is simply dummy text of the printing and
							typesetting industry.</p>
						<div class="group-form mb-xl-20">
							<div class="input-group">
								<input type="email" name="#" class="form-control form-control-submit">
								<div class="input-group-append">
									<button>Subscribe</button>
								</div>
							</div>
						</div>
						<div class="register-info-box text-center">
							<div class="icon-sec">
								<i class="pe-7s-id"></i>
							</div>
							<h6><a routerLink="/" class="text-light-black">Register to Vote</a></h6>
							<p class="text-light-white mb-0">Lorem Ipsum is simply dummy text.</p>
						</div>
					</div>
					<div class="verticle-newsletter-img">
						<img src="assets/img/register.jpg" class="image-fit" alt="img">
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--shopping end section-->

<!--Testimonail-->
<app-testimonials></app-testimonials>
<!--Testimonail End-->

<!-- Blog section -->
<app-blogpost></app-blogpost>

<!-- Start Paravideo and icon -->
<section class="section-padding-top paravideo-icon-sec">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="icon-sec">
					<div class="row">
						<div class="col" *ngFor="let iconpost of iconposts">
							<div class="icon-box">
								<a routerLink="/">
									<img src="{{iconpost.icon}}" alt="img">
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<div class="section-padding-bottom paravideo-sec">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="video-sec">
					<div class="row no-gutters">
						<div class="col-lg-8">
							<div class="full-height video-sec-left section-padding">
								<div class="video-sec-left-inner">
									<ul>
										<li class="fs-14 text-custom-pink mb-xl-20">
											<i class="fas fa-calendar-week"></i>
											<span class="text-custom-white">24 May 2022</span>
										</li>
										<li class="fs-14 text-custom-pink mb-xl-20">
											<i class="fas fa-map-marker-alt"></i>
											<span class="text-custom-white">Newyork (USA)</span>
										</li>
									</ul>
									<h3 class="text-custom-white">Upcoming Campaign</h3>
									<div class="upcoming-cam-timer">
										<div class="countdown-box sigma_countdown-timer" data-countdown="2022/12/06">

										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-4">
							<div class="full-height video-sec-right">
								<div class="video_wrapper video_wrapper_full js-videoWrapper">
									<iframe class="videoIframe js-videoIframe"
										src="https://www.youtube.com/embed/AdZrEIo6UYU"
										data-src="https://www.youtube.com/embed/AdZrEIo6UYU?autoplay=1"
										allow="autoplay"></iframe>
									<div class="videoPoster js-videoPoster">
										<img src="assets/img/vid.jpg" class="image-fit" alt="#">
										<div class="video-inner video-btn-wrapper blob play-btn ">
											<a href="https://www.youtube.com/watch?v=AdZrEIo6UYU" class="video-popup" #video2>
												<i class="pe-7s-play"></i>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- End Paravideo and icon -->


<!-- END/BLOG Section -->
<app-newsletter2></app-newsletter2>

<app-footer></app-footer>
