<app-topbar></app-topbar>
<app-navbar></app-navbar>
<!-- breadcrumb -->
<app-breadcrumbs></app-breadcrumbs>
<!-- breadcrumb -->
<section class="compare-page-wrapper bg-light-white section-padding">
  <!-- compare main wrapper start -->
  <div class="container">
    <div class="section-bg-color">
      <div class="row">
        <div class="col-lg-12">
          <!-- Wishlist Table Area -->
          <div class="cart-table table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th class="pro-remove">Remove</th>
                  <th class="pro-thumbnail">Thumbnail</th>
                  <th class="pro-title">Product</th>
                  <th class="pro-price">Price</th>
                  <th class="pro-quantity">Stock Status</th>
                  <th class="pro-subtotal">Add to Cart</th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let wishlist of wishlistposts">
                  <td class="pro-remove"><a routerLink="/shop-detail"><i class="pe-7s-trash"></i></a></td>
                  <td class="pro-thumbnail">
                    <a routerLink="/shop-detail">
                      <img class="img-fluid" src="{{wishlist.img}}" alt="Product" />
                    </a>
                  </td>
                  <td class="pro-title"><a routerLink="/shop-detail">{{wishlist.productname}}</a></td>
                  <td class="pro-price"><span>$295.00</span></td>
                  <td class="pro-quantity">
                    <span class="text-success">{{wishlist.instock}}</span>
                    <span class="text-danger" *ngIf="wishlist.outstock">{{wishlist.outstock}}</span>
                  </td>
                  <td class="pro-subtotal">
                    <a routerLink="/checkout" class="btn btn-solid" *ngIf="wishlist.instock">{{wishlist.btntext}}</a>
                    <a routerLink="/checkout" class="btn btn-solid disabled" *ngIf="wishlist.outstock">{{wishlist.btntext}}</a>
                  </td>

                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- compare main wrapper end -->
</section>
<app-newsletter2></app-newsletter2>
<app-footer></app-footer>
