<!-- footer -->
	<!-- <footer class="section-padding bg-light-theme pt-0 u-line bg-custom-primary">
<swiper [config]="instagramconfig" [slidesPerView]="2" [loop]="true" class="u-line instagram-slider swiper-container">
			<ul class="hm-list hm-instagram swiper-wrapper">
				<li class="swiper-slide" *ngFor="let instagrampost of instagramposts">
					<a href="javascript:void(0)">
						<img src="{{ instagrampost.img }}" alt="instagram">
					</a>
					<div class="insta-icon">
						<div><i class="fab fa-instagram text-red-light"></i>
						</div>
					</div>
				</li>
			</ul>
		</swiper>
		<div class="container-fluid custom-container">
			<div class="row">
				<div class="col-xl col-lg-4 col-md-4 col-sm-6">
					<div class="footer-contact">
						<h6 class="text-custom-white">About Us</h6>
						<div class="logo mb-xl-20">
							<a href="javascript:void(0)">
								<img src="{{ logo }}" class="img-fluid" alt="img">
							</a>
						</div>
						<p class="text-white">For the campaign. To infinity!</p>
					</div>
				</div>
				<div class="col-xl col-lg-4 col-md-4 col-sm-6">
					<div class="footer-links">
						<h6 class="text-custom-white">Useful Links</h6>
						<ul>
							<li><a routerLink="/" class="text-white fw-500">Home</a>
							</li>
							<li><a routerLink="/about-us" class="text-white fw-500">About Us</a>
							</li>
							<li><a routerLink="/blog-detail" class="text-white fw-500">Blog</a>
							</li>
							<li><a routerLink="/donation-detail" class="text-white fw-500">Donation</a>
							</li>
							<li><a routerLink="/story-detail" class="text-white fw-500">Story</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="col-xl col-lg-4 col-md-4 col-sm-6">
					<div class="footer-links">
						<h6 class="text-custom-white">My Account</h6>
						<ul>
							<li><a routerLink="/login" class="text-white fw-500">Login</a>
							</li>
							<li><a routerLink="/register" class="text-white fw-500">Signup</a>
							</li>
							<li><a routerLink="/wishlist" class="text-white fw-500">Wishlist</a>
							</li>
							<li><a routerLink="/checkout" class="text-white fw-500">Checkout</a>
							</li>
							<li><a routerLink="/order" class="text-white fw-500"> order</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="col-xl col-lg-4 col-md-4 col-sm-6">
					<div class="footer-links">
						<h6 class="text-custom-white">Other Links</h6>
						<ul>
							<li><a routerLink="/donation-detail" class="text-white fw-500">Donation Detail</a>
							</li>
							<li><a routerLink="/story-detail" class="text-white fw-500">Story Detail</a>
							</li>
							<li><a routerLink="/order" class="text-white fw-500">Track Order</a>
							</li>
							<li><a routerLink="/blog-detail" class="text-white fw-500">Blog</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="col-xl col-lg-4 col-md-4 col-sm-6">
					<div class="footer-links">
						<h6 class="text-custom-white">Contact info</h6>
						<ul class="contact-info">
							<li>
								<a href="javascript:void(0)" class="text-white"> <span><i class="pe-7s-timer"></i></span>
									Monday - Friday: 9:00 AM - 06:00 PM</a>
							</li>
							<li>
								<a href="javascript:void(0)" class="text-white"> <span><i class="pe-7s-mail"></i></span>
									info@example.com</a>
							</li>
							<li>
								<a href="javascript:void(0)" class="text-white"> <span><i class="pe-7s-call"></i></span>
									(+348) 123 456 7890</a>
							</li>
							<li>
								<a href="javascript:void(0)" class="text-white"> <span><i class="pe-7s-map-marker"></i></span>
									(+348) 123 456 7890</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</footer> -->
	<div class="copyright bg-black">
		<div class="container-fluid custom-container">
			<div class="row">
				<div class="col-lg-4">
					<div class="payment-logo mb-md-20">
						<div class="payemt-icon">
							<img src="assets/img/footer-bottom-img.png" alt="#">
						</div>
					</div>
				</div>
				<div class="col-lg-4 text-center medewithlove align-self-center"> <a href="https://doneconcepts.com/" class="text-custom-white">Made with <i class="fas fa-heart"></i> by Done Concepts</a>
				</div>
				<div class="col-lg-4">
					<div class="copyright-text"> <span class="text-white">© <a href="javascript:void(0)" class="text-white">Done Concepts</a> - 2022 | All Right Reserved</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- footer -->
