import { Component, ElementRef, Input, OnInit, AfterViewInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {

  @Input()
  videourl!: string;

  @Input()
  controlsPosition: string = 'bottom';

  @ViewChild('video') videoElement!: ElementRef;

  paused = false;
  muted = true;

  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    const video: HTMLVideoElement = this.videoElement.nativeElement;
    video.muted = this.muted;
    video.play();
    this.paused = video.paused;
    console.log(video.paused);
  }

  public togglePlay() {
    const video: HTMLVideoElement = this.videoElement.nativeElement;

    if(this.paused) {
      video.play();
    } else {
      video.pause();
    }
    this.paused = !this.paused;
  }

  public toggleMute() {
    const video: HTMLVideoElement = this.videoElement.nativeElement;

    this.muted = !this.muted;
    video.muted = this.muted;
  }

}
