<app-navbar></app-navbar>
<!-- OBI HEADER VIDEO START -->
<main id="main-content" class="p-relative">
  <section class="p-relative">
    <div class="container-fluid no-padding">
      <div class="row no-gutters">
        <div class="col-md-12">
          <app-video [videourl]="obivideo"></app-video>
          <div class="transform-center z-index" style="margin-top: 100px">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-8 align-self-center align-item-center">
                  <a
                    routerLink="/shop"
                    class="btn-solid with-line btn-big mt-20"
                    ><span>Shop Now <i class="fas fa-caret-right"></i></span
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- OBI HEADER VIDEO END -->

  <!-- DATTI HEADER VIDEO START -->
  <section class="p-relative">
    <div class="container-fluid no-padding">
      <div class="row no-gutters">
        <div class="col-md-12">
          <!-- datti video -->
          <app-video [videourl]="dattivideo"></app-video>
          <div class="transform-center z-index">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-8 align-self-center align-item-center">
                  <a
                    routerLink="/donate"
                    class="btn-big btn-donation btn-primary mt-20 rounded"
                    ><span>Donate</span></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- DATTI HEADER VIDEO END -->

  <!-- services section -->
  <!-- <section class="service-type">
    <div class="row">
      <div
        class="col-12 {{ servicetypepost.parentclass }}"
        *ngFor="let servicetypepost of servicetypeposts"
      >
        <div class="service-box">
          <div class="service-box-wrapper">
            <div class="service-icon-box">
              <img src="{{ servicetypepost.img }}" alt="" class="img-fluid" />
            </div>
            <div class="service-text-box">
              <p>{{ servicetypepost.title }}</p>
              <h6>{{ servicetypepost.subtitle }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <!-- end services section -->

  <!-- VOLUNTEERS-DONATE HEADER VIDEO START -->
  <section class="p-relative">
    <div class="container-fluid no-padding">
      <div class="row no-gutters">
        <div class="col-md-12">
          <app-video [videourl]="volunteersvideo" [controlsPosition]="'top'"></app-video>
        </div>
      </div>
    </div>
  </section>
  <!-- VOLUNTEERS-DONATE HEADER VIDEO END -->

  <!--shopping section-->
  <!-- <section class="section-padding our-product">
   <app-latestproducts></app-latestproducts>
</section> -->
  <!--shopping section end-->
  <!-- advertisement banner-->
  <!-- <app-advertisement></app-advertisement> -->
  <!-- advertisement banner-->

  <!--Testimonail-->
  <!-- <app-testimonials></app-testimonials> -->
  <!--Testimonail End-->
  <!-- Blog section -->
  <!-- <app-blogpost></app-blogpost> -->
  <!-- END/BLOG Section -->
  <!-- stats and countdown -->
  <div class="section-padding-bottom paravideo-sec">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="video-sec">
            <div class="row no-gutters">
              <div class="col-lg-8">
                <div class="full-height video-sec-left section-padding">
                  <div class="video-sec-left-inner">
                    <ul>
                      <li class="fs-14 text-custom-pink mb-xl-20">
                        <i class="fas fa-calendar-week"></i>
                        <span class="text-custom-white">23 February 2023</span>
                      </li>
                      <li class="fs-14 text-custom-pink mb-xl-20">
                        <i class="fas fa-map-marker-alt"></i>
                        <span class="text-custom-white">Nigeria</span>
                      </li>
                    </ul>
                    <h3 class="text-custom-white">
                      Upcoming General Elections
                    </h3>
                    <div class="upcoming-cam-timer">
                      <div class="countdown-box sigma_countdown-timer">
                        <div class="counter-box">
                          <span>03</span>
                          <p class="lead m-0 mt-2 small">Items Purchased</p>
                        </div>
                        <div class="counter-box">
                          <span>29</span>
                          <p class="lead m-0 mt-2 small">Page Views</p>
                        </div>
                        <div class="counter-box p-relative">
                          <span>52</span>
                          <p class="lead m-0 mt-2 small">
                            Reviews
                            <span
                              class="add-review badge badge-pill btn-warning"
                              >Add a review</span
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="full-height video-sec-right">
                  <div class="video_wrapper video_wrapper_full js-videoWrapper">
                    <iframe
                      class="videoIframe js-videoIframe"
                      src="https://www.youtube.com/watch?v=ZnxvbvehDG8"
                      data-src="https://www.youtube.com/watch?v=ZnxvbvehDG8?autoplay=1"
                      allow="autoplay"
                    ></iframe>
                    <div class="videoPoster js-videoPoster">
                      <img
                        src="assets/img/obidient-plaques.jpeg"
                        class="image-fit"
                        alt="#"
                      />
                      <div class="video-inner video-btn-wrapper blob play-btn">
                        <a
                          href="https://www.youtube.com/watch?v=ZnxvbvehDG8"
                          class="video-popup"
                          #video2
                        >
                          <i class="pe-7s-play"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- stats and countdown -->
  <!-- Start Paravideo and icon -->
  <section
    class="section-padding-top section-padding-bottom paravideo-icon-sec"
  >
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="icon-sec">
            <div class="row">
              <div class="col" *ngFor="let iconpost of iconposts">
                <div class="icon-box">
                  <a routerLink="/">
                    <img src="{{ iconpost.icon }}" alt="img" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- End Paravideo and icon -->

  <app-newsletter2></app-newsletter2>
  <app-topbar></app-topbar>
</main>
<app-footer></app-footer>
