<div>
  <div class="main-banner video-banner">
    <video
      playsinline="playsinline"
      muted
      autoplay
      loop="loop"
      #video
    >
      <source src="{{ videourl }}" type="video/mp4" />
    </video>
    <div class="overlay overlay-bg-dark-2">

    </div>
    <div class="video-controls" [ngClass]="'controls-' +  controlsPosition">
      <div class="btn-group" role="group" aria-label="Basic example">
        <button type="button" class="btn text-custom-white" (click)="togglePlay()">
          <i class="fas fa-pause" *ngIf="!paused"></i>
          <i class="fas fa-play" *ngIf="paused"></i>
        </button>
        <button type="button" class="btn text-custom-white" (click)="toggleMute()">
          <i class="fas fa-volume-up" *ngIf="!muted"></i>
          <i class="fas fa-volume-mute" *ngIf="muted"></i>
        </button>
      </div>
    </div>
  </div>
</div>
