<app-navbar></app-navbar>
<!--banner-->
<div class="inner-wrapper">
	<div class="container-fluid no-padding">
		<div class="row no-gutters">
			<div class="col-md-6">
				<div class="main-banner">
					<img src="{{ bannerimg }}" class="img-fluid full-width main-img" alt="banner">
					<div class="overlay-2 main-padding"></div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="section-2 main-page main-padding bg-theme-primary">
					<div class="top-nav">
						<h6><a routerLink="/about-us" class="text-light-green fw-100">Explore</a></h6>
						<h6><a routerLink="/login" class="text-light-green fw-100">Sign in</a></h6>
					</div>
					<div class="login-box">
						<div class="col-12">
							<h1 class="text-light-black fw-600">We've funded 44,007 Dollars over 10 million peoples</h1>
							<p>Search Your Nearest Store.</p>
							<div class="input-group row">
								<div class="input-group2 col-xl-8">
									<input type="search" class="form-control form-control-submit"
										placeholder="Enter street address or zip code"
										value="1246 57th St, Brooklyn, NY, 11219">
									<div class="input-group-prepend">
										<button class="input-group-text text-light-green"><i
												class="fab fa-telegram-plane"></i>
										</button>
									</div>
								</div>
								<div class="input-group-append col-xl-4">
									<button class="btn-solid with-line btn-big full-width" type="button">Search</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- sell section -->
<section class="recent-order section-padding">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-12">
				<div class="section-header-left title">
					<h3 class="text-light-black header-title">Help Us Fund Our Campaign</h3>
					<span class="fs-16"><a routerLink="/order">See All Bestseller</a></span>
				</div>
			</div>
			<div class="col-xl-2 col-lg-4 col-md-6 col-sm-6" *ngFor="let shoppost of shopposts">
				<div class="product-box mb-md-20">
					<div class="product-img">
						<a routerLink="/shop-detail">
							<img src="{{shoppost.img}}" class="img-fluid full-width" alt="product-img">
						</a>
						<div class="product-badge">
							<div class="product-label {{shoppost.label1cls}}" *ngIf="shoppost.label1cls">
								<span>{{shoppost.label1}}</span>
							</div>
							<div class="product-label {{shoppost.label3cls}}" *ngIf="shoppost.label3cls">
								<span>{{shoppost.label3}}</span>
							</div>
							<div class="product-label {{shoppost.label2cls}}" *ngIf="shoppost.label2cls">
								<span>{{shoppost.label2}}</span>
							</div>
						</div>
						<div class="button-group">
							<a routerLink="/wishlist" placement="left" title="" ngbTooltip="Add to wishlist"
								tabindex="-1">
								<i class="pe-7s-like"></i>
							</a>
							<a routerLink="/" placement="left" title="" ngbTooltip="Quick View">
								<span>
									<i class="pe-7s-search"></i>
								</span>
							</a>
						</div>
						<div class="cart-hover">
							<a routerLink="/shop-detail" class="btn-cart fw-600" tabindex="-1">{{shoppost.carttext}}</a>
						</div>
					</div>
					<div class="product-caption text-center">
						<div class="product-status">
							<ul class="product-raised">
								<li><strong>{{shoppost.soldtitle}}:</strong> {{shoppost.soldprice}}</li>
								<li><strong>{{shoppost.goaltitle}}:</strong><span class="text-highlight">
										{{shoppost.goalprice}}</span></li>
							</ul>
							<div class="progress">
								<div class="progress-bar progress-bar-color" style="width: 75%;"></div>
							</div>
						</div>
						<h6 class="product-title fw-500 mt-10"><a routerLink="/shop-detail"
								class="text-color-secondary">{{shoppost.title}}</a></h6>
						<div class="product-money mt-10"> <span
								class="text-color-primary fw-600 fs-16">${{shoppost.price}}</span>
							<span class="text-price">${{shoppost.discountprice}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- sell section end -->
<!-- advertisement banner-->
<section class="section-padding advertisement-banner-1 bg-banner-mission center-bg-effect">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-12">
				<div class="sigma-adv-content text-center mx-center-width">
					<h3 class="text-white">We Are In A<span class="text-white"> Mission</span> To Help Helpless</h3>
					<p class="text-white">There are many variations of passages of Lorem Ipsum available, but the
						majority have suffered alteration in some form, by injected humour.</p>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- advertisement banner-->
<!-- Hot Deals -->
<section class="ex-collection section-padding bg-theme-primary">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-12">
				<div class="section-header-left">
					<h3 class="text-light-black header-title title">Our Hot Deals</h3>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-6 col-md-12" *ngFor="let hotdeal of hotdeals">
				<div class="story-wrapper">
					<img src="{{ hotdeal.img }}" alt="" class="full-width img-fluid mx-auto d-block">
					<div class="story-box-content story-content-wrapper">
						<span class="story-badge bg-custom-primary text-color-secondary">{{ hotdeal.badge }}</span>
						<h5><a routerLink="/donation-detail">{{ hotdeal.title }}</a></h5>
						<a routerLink="/donation-detail" class="btn btn-text btn-text-white">{{ hotdeal.linktext }}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--Hot deals End-->
<section class="section-padding our-product">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-12">
				<div class="section-header-left">
					<h3 class="text-light-black header-title title"> Help us fund our campaigns</h3>
				</div>
			</div>
			<div class="col-xl-9 col-lg-8 col-md-8">
				<div class="row">
					<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6" *ngFor="let bestseller of bestsellers">
						<div class="product-box mb-md-20">
							<div class="product-img">
								<a routerLink="/shop-detail">
									<img src="{{ bestseller.img }}" class="img-fluid full-width" alt="product-img">
								</a>
								<div class="product-badge">
									<div class="product-label {{ bestseller.labelcls }}">
										<span>{{ bestseller.label }}</span>
									</div>
								</div>
								<div class="button-group">
									<a routerLink="/wishlist" placement="left" title="" ngbTooltip="Add to wishlist"
										tabindex="-1">
										<i class="pe-7s-like"></i>
									</a>
									<a routerLink="/" placement="left" title="" ngbTooltip="Quick View">
										<span>
											<i class="pe-7s-search"></i>
										</span>
									</a>
								</div>
								<div class="cart-hover">
									<a routerLink="/shop-detail" class="btn-cart  fw-600"
										tabindex="-1">{{ bestseller.carttext }}</a>
								</div>
							</div>
							<div class="product-caption text-center">
								<div class="product-status">
									<ul class="product-raised">
										<li><strong>{{ bestseller.soldtitle }}:</strong> {{ bestseller.soldprice }}</li>
										<li><strong>{{ bestseller.goaltitle }}:</strong><span class="text-highlight">
												{{ bestseller.soldprice }}</span></li>
									</ul>
									<div class="progress">
										<div class="progress-bar progress-bar-color" style="width: 75%;"></div>
									</div>
								</div>
								<h6 class="product-title fw-500 mt-10"><a routerLink="/shop-detail"
										class="text-color-secondary">{{ bestseller.title }}</a></h6>
								<div class="product-money mt-10"> <span
										class="text-color-primary fw-600 fs-16">${{ bestseller.price }}</span>
									<span class="text-price">${{ bestseller.discountprice }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-3 col-lg-4 col-md-4">
				<div class="large-product-box  p-relative">
					<div class="featured-product-box box-shadow">
						<div class="featured-pro-title">
							<h4 class="fs-22"><strong class="text-color-primary"> Vote </strong>now don't postpone</h4>
						</div>
						<div class="featured-pro-content">
							<div class="featured-pro-text">
								<h6><a routerLink="/homepage5"> Vote for a better community.</a></h6>
								<p>Lorem ipsum dolor sit amet, ctetur adipiscing elit, sed do eiusmod</p>
								<p class="price">$244</p>
							</div>
						</div>
						<div class="featured-pro-img">
							<img src="{{ featuredproimg }}" alt="pro-img" class="img-fluid mx-auto d-block">
						</div>
						<div class="featured-pro-timer">
							<div class="countdown-box timer-wrap" data-countdown="2022/12/06"></div>
						</div>
						<div class="featured-pro-bottom">
							<ul>
								<li>Votes: <strong>150000 </strong></li>
								<li>Goal: <strong>200000</strong> </li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--shopping end section-->
<!-- advertisement banner-->
<section class="section-padding advertisement-banner-1 bg-banner-sponsr">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-lg-12">
				<div class="sigma-adv-content-1 center-block text-center">
					<h6 class="sub-head">We do it for all people</h6>
					<h3 class="text-white heading">Delivering help and hope to <span
							class="text-color-primary">children</span> through sponsorship.</h3>
					<a routerLink="/register" class="btn btn-text btn-text-white">Register Today</a>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- advertisement banner-->
<!--Latest Product-->
<section class="section-padding our-product bg-theme-primary">
	<app-latestproducts></app-latestproducts>
</section>
<!--End Latest Product-->
<section class="ex-collection section-padding">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-12">
				<div class="section-header-left">
					<h3 class="text-light-black header-title title">Our Hot Deals</h3>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-6 col-md-12" *ngFor="let topdeal of topdeals">
				<div class="story-wrapper">
					<img src="{{ topdeal.img }}" alt="" class="full-width img-fluid mx-auto d-block">
					<div class="story-box-content story-content-wrapper">
						<span class="story-badge bg-custom-primary text-color-secondary">{{ topdeal.badge }}</span>
						<h5><a routerLink="/donation-detail">{{ topdeal.title }}</a></h5>
						<a routerLink="/donation-detail" class="btn btn-text btn-text-white">{{ topdeal.linktext }}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<app-newsletter1></app-newsletter1>
<app-footer></app-footer>
