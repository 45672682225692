import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import 'jquery-countdown';

@Component({
  selector: 'app-advertisement',
  templateUrl: './advertisement.component.html',
  styleUrls: ['./advertisement.component.css']
})
export class AdvertisementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    var coundwn = $('.sigma_countdown-timer-white');
    $(coundwn).each(function () {
      (<any>$(coundwn)).countdown(coundwn.data('countdown'), function (event: { strftime: (arg0: string) => string; }) {
        $(coundwn).html(
          event.strftime('<div class="time-box"> <span>%D</span>Days</div><div class="time-box"> <span>%H</span>Hr</div><div class="time-box"> <span>%M</span>Min</div><div class="time-box"> <span>%S</span>Sec</div>')
        );
      });
    });
  }

}
