<!-- top-Navigation -->
  <div class="top-header">
    <div class="container-fluid custom-container">
      <div class="row">
        <div class="col-md-6">
          <div class="top-left">
            <ul class="top-info">
              <li><a href="javascript:void(0)"><i class="fas fa-phone-alt"></i> +000 123 45678</a></li>
              <li><a href="javascript:void(0)"><i class="fas fa-envelope"></i> info@example.com</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-6">
          <div class="top-right">
            <ul class="list-inline">
              <li class="list-inline-item"><a href="https://www.facebook.com/NigeriaLabourParty"><i class="fab fa-facebook-square"></i></a> </li>
              <li class="list-inline-item"><a href="https://twitter.com/NgLabour"><i class="fab fa-twitter-square"></i></a> </li>
              <li class="list-inline-item"><a href="https://instagram.com/nglabourparty"><i class="fab fa-instagram"></i></a> </li>
              <li class="list-inline-item"><a href="https://www.youtube.com/c/LabourPartyNig"><i class="fab fa-youtube"></i></a> </li>
            </ul>
            <a routerLink="/shop" class="top-btn">SHOP NOW</a>
          </div>
        </div>
      </div>
    </div>
  </div>
